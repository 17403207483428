export default {
  SET_ITEM(state, item) {
    state.element = item
  },
  SET_ITEM_TOPIC(state, topic) {
    state.element_topic = topic
  },

  SET_ELEMENT_MESSAGES(state, messages) {
    state.element_messages = messages
  },

  ADD_MESSAGE(state, message) {
    if (state.element && state.element.id === message.station_room.id)
      state.element_messages.push(message)
  },
  DELETE_MESSAGE(state, message) {
    let index = state.element_messages.findIndex((x) => x.id === message.id)
    if (index !== -1) {
      state.element_messages[index].deleted = 1
      state.element_messages[index].message = null
    }
  },

  CLEAR_TEXT(state, clear) {
    state.clear_text = clear
  }
}
