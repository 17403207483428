export default {
  handleUserConnection({ commit }, user) {
    return new Promise(async (resolve, reject) => {
      try {
        commit("USER_CONNECTION", user)
        resolve(user)
      } catch (error) {
        reject(error)
      }
    })
  },
  setElement({ commit }, data) {
    return new Promise(async (resolve, reject) => {
      try {
        commit("SET_ITEM", data)
        resolve(data)
      } catch (error) {
        reject(error)
      }
    })
  },
  setElementTopic({ commit }, topic) {
    commit("SET_ITEM_TOPIC", topic)
  },
  handleMsgDeletion({ commit }, message) {
    commit("DELETE_MESSAGE", message)
  },
  setElementMessages({ commit }, messages) {
    return new Promise(async (resolve, reject) => {
      try {
        commit("SET_ELEMENT_MESSAGES", messages)
        resolve(messages)
      } catch (error) {
        reject(error)
      }
    })
  },
  addChatMessage({ commit }, message) {
    return new Promise(async (resolve, reject) => {
      try {
        commit("ADD_MESSAGE", message)
        resolve(message)
      } catch (error) {
        reject(error)
      }
    })
  },
  clearText({ commit }, clear) {
    return new Promise(async (resolve, reject) => {
      try {
        commit("CLEAR_TEXT", clear)
        resolve(clear)
      } catch (error) {
        reject(error)
      }
    })
  }
}
