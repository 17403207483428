import Vue from "vue"
import Vuex from "vuex"
import VuexPersistence from "vuex-persist"
import createWebSocketPlugin from "./plugin"
import socket from "../socket/socket"

Vue.use(Vuex)

const vuexLocal = new VuexPersistence({
  storage: window.localStorage
})

const websocketPlugin = createWebSocketPlugin(socket)

import state from "./state"
import getters from "./getters"
import mutations from "./mutations"
import actions from "./actions"
import moduleAuth from "./auth/moduleAuth"
import moduleChat from "./chat/moduleChat"
import moduleRoom from "./room/moduleRoom"
import moduleCountry from "./country/moduleCountry"
import moduleGlobal from "./global/moduleGlobal"
import moduleUser from "./user/moduleUser"

import moduleTitle from "./title/moduleTitle"
import modulePicture from "./picture/modulePicture"
import modulePost from "./post/modulePost"
import moduleRadio from "./radio/moduleRadio"
import moduleVideo from "./video/moduleVideo"
import moduleStationRoom from "./station_room/moduleStationRoom"
import moduleUserReportCategory from "./userReportCategory/moduleUserReportCategory"
import moduleReportReason from "./reportReason/moduleReportReason"
import moduleVerification from "./verification/moduleVerification"
import moduleRandomPhoto from "./random_photo/moduleRandomPhoto"
import moduleStory from "./story/moduleStory"
import moduleCuts from "./jeviCuts/moduleCuts"
import modulePublicRoom from "./public_room/modulePublicRoom"
import moduleRandomChat from "./random_chat/moduleRandomChat"
import moduleHighlight from "./highlight/moduleHighlight"

const store = new Vuex.Store({
  getters,
  mutations,
  state,
  actions,
  modules: {
    auth: moduleAuth,
    chat: moduleChat,
    global: moduleGlobal,
    user: moduleUser,
    title: moduleTitle,
    country: moduleCountry,
    picture: modulePicture,
    public_room: modulePublicRoom,
    radio: moduleRadio,
    random_chat: moduleRandomChat,
    room: moduleRoom,
    stationRoom: moduleStationRoom,
    story: moduleStory,
    jeviCuts: moduleCuts,
    post: modulePost,
    video: moduleVideo,
    userReportCategory: moduleUserReportCategory,
    reportReason: moduleReportReason,
    verification: moduleVerification,
    random_photo: moduleRandomPhoto,
    highlight: moduleHighlight
  },
  plugins: [vuexLocal.plugin, websocketPlugin]
})

export default store
