import getFormData from "@/utils/convertToFormData"
import axios from "@/utils/axios.js"

export default {
  setItem({ commit }, item) {
    commit("SET_ITEM", item)
  },
  updateElement({ commit }, data) {
    commit("UPDATE_ITEM", data)
  },
}
