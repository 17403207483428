export default [
  {
    path: "/highlight/:datetime",
    name: "Highlights",
    component: () => import(/* webpackPrefetch: true */ /* webpackChunkName: "post" */"@/views/highlight/Detail.vue"),
    meta: {
      rule: "isGlobal",
      //authRequired: true,
    }
  },
  {
    path: "/user/:username/post/:datetime",
    name: "Posts",
    component: () => import(/* webpackPrefetch: true */ /* webpackChunkName: "post" */"@/views/post/DetailV2.vue"),
    meta: {
      rule: "isGlobal",
      //authRequired: true,
      pageTitle: "post"
    }
  },
  {
    path: "/post/:id",
    name: "Posts",
    component: () => import(/* webpackPrefetch: true */ /* webpackChunkName: "post" */"@/views/post/Detail.vue"),
    meta: {
      rule: "isGlobal",
      //authRequired: true,
      pageTitle: "post"
    }
  },
  {
    path: "/post_by_hashtag/:id",
    name: "Posts by Hashtag",
    component: () => import(/* webpackPrefetch: true */ /* webpackChunkName: "post" */"@/views/post/ByHashtag.vue"),
    meta: {
      rule: "isGlobal",
      authRequired: true,
      pageTitle: "posts_by_hashtag"
    }
  },
  {
    path: "/video",
    name: "Videos",
    component: () => import(/* webpackPrefetch: true */ /* webpackChunkName: "post" */"@/views/video/Index.vue"),
    meta: {
      rule: "isGlobal",
      authRequired: true,
      pageTitle: "videos",
      icon: "/static/icons/video.svg"
    }
  }
]
