export default {
  ADD_ITEM(state, item) {
    state.unpaginated_elements.push(item)
  },
  SET_JEVI_CUTS(state, items) {
    state.jevi_cuts = items
  },

  UPDATE_ITEM(state, item) {
    let userCutIndex
    let cutIndex
    let userStory

    if (state.element.length > 0 && state.element[0].id_user === item.id_user) {
      cutIndex = state.element.findIndex((x) => x.id === item.id)
      if (cutIndex !== -1) {
        state.element[cutIndex] = item
      }
    }

    userCutIndex = state.unpaginated_elements.findIndex(
      (x) => x.id == item.id_user
    )

    if (userCutIndex !== -1) {
      userStory = { ...state.unpaginated_elements[userCutIndex] }
      cutIndex = state.unpaginated_elements[userCutIndex].cuts.findIndex(
        (x) => x.id === item.id
      )

      if (cutIndex !== -1) {
        userStory[cutIndex] = item
        Object.assign(state.unpaginated_elements[userCutIndex], userStory)
      }
    }
  },

  SET_ITEMS_NO_PAGINATE(state, items) {
    state.unpaginated_elements = items
  },
  ADD_USER_CUTS(state, item) {
    let index = state.unpaginated_elements.findIndex((x) => x.id === item.id)
    if (index !== -1) {
      Object.assign(state.unpaginated_elements[index], item)
    } else {
      state.unpaginated_elements.unshift(item)
    }
  },

  SET_ITEM(state, data) {
    state.element = data.cuts
    let datax = { ...data }
    delete datax.cuts
    state.element_user = datax
  },
  SET_LOAD_NEW(state, status) {
    state.load_new = status
  },

  MARK_AS_VIEWED(state, data) {
    let userCutIndex
    let CutIndex
    let item

    if (
      state.element.length > 0 &&
      state.element[0].id_user === data.id_user_story
    ) {
      CutIndex = state.element.findIndex((x) => x.id === data.id_story)
      if (CutIndex !== -1) {
        state.element[CutIndex].viewed = 1
      }
    }

    userCutIndex = state.unpaginated_elements.findIndex(
      (x) => x.id == data.id_user_story
    )

    if (userCutIndex !== -1) {
      item = state.unpaginated_elements[userCutIndex]
      CutIndex = item.cuts.findIndex((x) => x.id === data.id_story)
      if (CutIndex !== -1) {
        item.cuts[CutIndex].viewed = 1
        if (item.cuts.every((x) => x.viewed === 1)) item.cuts_viewed = 1
        Object.assign(state.unpaginated_elements[userCutIndex], item)
      }
    }
  },
  DELETE_ELEMENT(state, data) {
    state.load_new = true
    let userCutIndex

    if (state.element.length > 0 && state.element[0].id_user === data.id_user) {
      state.element = state.element.filter((x) => x.id !== data.id)
    }

    userCutIndex = state.unpaginated_elements.findIndex(
      (x) => x.id == data.id_user
    )

    let element = { ...state.unpaginated_elements[userCutIndex] }
    element.cuts = element.cuts.filter((x) => x.id !== data.id)
    if (element.cuts.length === 0) {
      state.unpaginated_elements.splice(userCutIndex, 1)
    } else {
      Object.assign(state.unpaginated_elements[userCutIndex], element)
    }
  },
  SET_RESUMABLE_VIDEO(state, data) {
    state.resumable_video = data
  },

  CHANGE_PROGRESS_RESUMABLE_VIDEO(state, data) {
    state.resumable_video.progress = data
  }
}
