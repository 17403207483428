import router from "@/router"
import getFormData from "@/utils/convertToFormData"
import axios from "@/utils/axios.js"

export default {
  changePassword(context, { id, payload }) {
    return new Promise(async (resolve, reject) => {
      await axios
        .put(`/user/change_password/${id}`, getFormData(payload))
        .then(({ data: response }) => {
          if ([200, 201].includes(response.code)) {
            router.push("/perfil")
          }
          resolve(response)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  setUsers({ commit }, data) {
    return new Promise(async (resolve, reject) => {
      try {
        commit("SET_ITEMS_NO_PAGINATE", data)
        resolve(data)
      } catch (error) {
        reject(error)
      }
    })
  },
  setSuggestedUsers({ commit }, data) {
    return new Promise(async (resolve, reject) => {
      try {
        commit("SET_SUGGESTED_ITEMS", data)
        resolve(data)
      } catch (error) {
        reject(error)
      }
    })
  },
  updateSuggestedUsers({ commit }, data) {
    return new Promise(async (resolve, reject) => {
      try {
        commit("UPDATE_SUGGESTED_ITEMS", data)
        resolve(data)
      } catch (error) {
        reject(error)
      }
    })
  },
  setSuggestedUsernames({ commit }, data) {
    return new Promise(async (resolve, reject) => {
      try {
        commit("SET_SUGGESTED_USERNAMES", data)
        resolve(data)
      } catch (error) {
        reject(error)
      }
    })
  }
}
