import Vue from "vue"
import store from "../store/store"

const timeUnits_en = {
  second: "second",
  seconds: "seconds",
  minute: "minute",
  minutes: "minutes",
  hour: "hour",
  hours: "hours",
  day: "day",
  days: "days",
  week: "week",
  weeks: "weeks",
  year: "year",
  years: "years"
}

const timeUnits_es = {
  second: "segundo",
  seconds: "segundos",
  minute: "minuto",
  minutes: "minutos",
  hour: "hora",
  hours: "horas",
  day: "día",
  days: "días",
  week: "semana",
  weeks: "semanas",
  year: "año",
  years: "años"
}

let browser_lang =
  navigator.language && ["es", "en"].includes(navigator.language.split("-")[0])
    ? navigator.language.split("-")[0]
    : "es"

if (store.state.auth.user) browser_lang = store.state.auth.user.lang

Vue.filter("transformDate", (value, type = "short") => {
  if (value) {
    const date = new Date(value)
    date.setDate(date.getDate() + 1)
    let options = { year: "numeric", month: "short" }
    if (type === "large") {
      options = { year: "numeric", month: "short", day: "numeric" }
    }
    if (type === "long") {
      options = { year: "numeric", month: "long", day: "numeric" }
    }

    return new Intl.DateTimeFormat(browser_lang, options).format(date)
  }
  return ""
})

Vue.filter("transformHour", (value) => {
  value = value
    .toString()
    .match(/^([01]\d|2[0-3])(:)([0-5]\d)(:[0-5]\d)?$/) || [value]

  if (value.length > 1) {
    // If value format correct
    value = value.slice(1) // Remove full string match value
    value[5] = +value[0] < 12 ? " AM" : " PM" // Set AM/PM
    value[0] = +value[0] % 12 || 12 // Adjust hours

    return value.join("")
  }

  return ""
})

Vue.filter("fromNow", (value) => {
  const fromNowObject = timeFromNow(value + "Z")
  let fromNowVanilla
  if (browser_lang === "es") {

    if(fromNowObject.time < 60 && (fromNowObject.unitOfTime === 'second' || fromNowObject.unitOfTime === 'seconds')) return `Hace un momento`
    fromNowVanilla = `Hace ${fromNowObject.time} ${
      timeUnits_es[fromNowObject.unitOfTime]
    }`
  }

  if (browser_lang === "en") {

    
    if(fromNowObject.time < 60 && (fromNowObject.unitOfTime === 'second' || fromNowObject.unitOfTime === 'seconds')) return `A few moment ago`
    fromNowVanilla = `${fromNowObject.time} ${
      timeUnits_en[fromNowObject.unitOfTime]
    } ago`
  }

  return fromNowVanilla
})

const timeFromNow = function (time) {
  // Get timestamps
  var unixTime = new Date(time).getTime()
  if (!unixTime) return
  var now = new Date().getTime()

  // Calculate difference
  var difference = unixTime / 1000 - now / 1000

  // Setup return object
  var tfn = {}

  // Check if time is in the past, present, or future
  tfn.when = "now"
  if (difference > 0) {
    tfn.when = "future"
  } else if (difference < -1) {
    tfn.when = "past"
  }

  difference = Math.abs(difference)

  // Calculate time unit
  if (difference / (60 * 60 * 24 * 365) > 1) {
    // Years
    tfn.time = Math.floor(difference / (60 * 60 * 24 * 365))
    tfn.unitOfTime = tfn.time > 1 ? "years" : "year"
  } else if (difference / (60 * 60 * 24 * 7) > 1) {
    // Weeks
    tfn.time = Math.floor(difference / (60 * 60 * 24 * 7))
    tfn.unitOfTime = tfn.time > 1 ? "weeks" : "week"
  } else if (difference / (60 * 60 * 24) > 1) {
    // Days
    tfn.time = Math.floor(difference / (60 * 60 * 24))
    tfn.unitOfTime = tfn.time > 1 ? "days" : "day"
  } else if (difference / (60 * 60) > 1) {
    // Hours
    tfn.time = Math.floor(difference / (60 * 60))
    tfn.unitOfTime = tfn.time > 1 ? "hours" : "hour"
  } else if (difference / 60 > 1) {
    // Hours
    tfn.time = Math.floor(difference / 60)
    tfn.unitOfTime = tfn.time > 1 ? "minutes" : "minute"
  } else {
    // Seconds
    tfn.time = Math.floor(difference)
    tfn.unitOfTime = tfn.time > 1 ? "seconds" : "second"
  }
  return tfn
}
