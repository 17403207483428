export default {
  getAll: (state) => {
    return state.elements
  },

  getItem: (state) => {
    return state.element
  },
  getAllUnpaginated: (state) => {
    return state.unpaginated_elements
  },
  getAllSuggested: (state) => {
    return state.suggested_elements
  }
}
