import state from "./moduleReportReasonState"
import actions from "./moduleReportReasonActions"
import getters from "./moduleReportReasonGetters"
import mutations from "./moduleReportReasonMutations"

export default {
  namespaced: true,
  state: state,
  mutations: mutations,
  actions: actions,
  getters: getters
}
