export default {
  SET_ITEMS_NO_PAGINATE(state, items) {
    state.unpaginated_elements = items
  },
  COMMENT_DELETED(state, data) {
    let index = state.unpaginated_elements.findIndex(
      (x) => x.id === data.id_post
    )
    if (index !== -1) state.unpaginated_elements[index].total_comments--
  }
}
