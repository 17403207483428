const createWebSocketPlugin = (socket) => {
  return (store) => {
    store.$socket = socket

    socket.on("join_station_room", (data) => {
      console.log("join_station_room", data)
      store.dispatch("stationRoom/setElement", data.data.station_room)
      store.dispatch(
        "stationRoom/setElementTopic",
        data.data.station_room.actual_topic
      )
    })

    socket.on("room_update", (data) => {
      console.log("room_update", data)
      store.dispatch("room/updateElement", data)
    })

    socket.on("station_room_update", (data) => {
      console.log("station_room_update", data)
      if (data.field_changed === "actual_topic")
        store.dispatch(
          "stationRoom/setElementTopic",
          data.station_room.actual_topic
        )
    })

    socket.on("message_deleted", (data) => {
      console.log("message_deleted", data)
      store.dispatch("stationRoom/handleMsgDeletion", data)
    })

    socket.on("leave_station_room", (data) => {
      console.log("leave_station_room", data)
    })

    socket.on("post_viewed", (data) => {
      console.log("post_viewed", data)
    })

    socket.on("story_viewed", (data) => {
      console.log("story_viewed", data)
      if (data.data.id_user_story === store.state.auth.user.id) {
        store.dispatch("auth/storyViewed", {
          ...data.data,
          stories: store.state.story.unpaginated_elements
        })
      }
      store.dispatch("story/markAsViewed", data.data)
    })

    socket.on("new_notification", (data) => {
      console.log("new_notification", data)
      store.dispatch("auth/updateNotifications")
      store.dispatch("auth/pushNewNotification", data.data.new_notification)
    })

    socket.on("jevi_app", (data) => {
      console.log("jevi_app", data)
      store.dispatch("auth/pushNewNotification", {
        ...data.data,
        title:
          store.state.lang === "en" ? data.data.title.en : data.data.title.es,
        description:
          store.state.lang === "en"
            ? data.data.description.en
            : data.data.description.es,
        type_child: "post",
        id: 1
      })
    })

    if (store.state.auth.user && store.state.auth.user.id_country) {
      socket.on("jevi_app_" + store.state.auth.user.id_country, (data) => {
        console.log("jevi_app_" + store.state.auth.user.id_country, data)
        store.dispatch("auth/pushNewNotification", {
          ...data.data,
          title:
            store.state.lang === "en" ? data.data.title.en : data.data.title.es,
          description:
            store.state.lang === "en"
              ? data.data.description.en
              : data.data.description.es,
          type_child: "post",
          id: 1
        })
      })
    }

    socket.on("user_connected", (data) => {
      console.log("user_connected", data)
      store.dispatch("chat/handleUserConnection", data.data)
      store.dispatch("auth/storiesAvailable", data.data.stories_available)

      if (!socket._callbacks["$jevi_app_" + data.data.id_country]) {
        socket.on("jevi_app_" + data.data.id_country, (datax) => {
          console.log("jevi_app_" + store.state.auth.user.id_country, datax)

          store.dispatch("auth/pushNewNotification", {
            ...datax.data,
            title:
              store.state.lang === "en"
                ? datax.data.title.en
                : datax.data.title.es,
            description:
              store.state.lang === "en"
                ? datax.data.description.en
                : datax.data.description.es,
            type_child: "post",
            id: 1
          })
        })
      }
    })

    socket.on("chat_message", (data) => {
      console.log("chat_message", data)
      store.dispatch("chat/addChatMessage", data)

      if (store.state.auth.user.id === data.user.id)
        store.dispatch("chat/clearText", true)
    })

    socket.on("room_message", (data) => {
      console.log("room_message", data)
      store.dispatch("room/addChatMessage", data)

      if (store.state.auth.user.id === data.user.id)
        store.dispatch("room/clearText", true)
    })

    socket.on("station_room_message", (data) => {
      console.log("station_room_message", data)
      store.dispatch("stationRoom/addChatMessage", data)

      if (store.state.auth.user.id === data.user.id)
        store.dispatch("stationRoom/clearText", true)
    })

    socket.on("public_room_message", (data) => {
      console.log("public_room_message", data)
      let datax = data
      if (data.id_user !== store.state.auth.user.id) {
        const existing_user_message_index =
          store.state.public_room.element_messages.findIndex(
            (x) => x.id_user === data.id_user
          )

        if (existing_user_message_index !== -1) {
          const old_message =
            store.state.public_room.element_messages[
              existing_user_message_index
            ]

          datax.followed_by_you_status = old_message.followed_by_you_status
          datax.blocked = old_message.blocked
          datax.liked = 0
          store.dispatch("public_room/addChatMessage", datax)
        } else {
          socket.emit("message_info", {
            id_message: data.id,
            id_user: store.state.auth.user.id,
            environment: "public_room"
          })
        }
      } else {
        store.dispatch("public_room/addChatMessage", datax)
      }

      if (store.state.auth.user.id === data.user.id)
        store.dispatch("public_room/clearText", true)
    })

    socket.on("message_info", (data) => {
      console.log("message_info", data)
      store.dispatch("public_room/addChatMessage", data.data)
    })

    socket.on("join_public_room", (data) => {
      console.log("join_public_room", data)
    })

    socket.on("leave_public_room", (data) => {
      console.log("leave_public_room", data)
    })

    socket.on("new_friend_request", (data) => {
      console.log("new_friend_request", data)
      const mutedSender =
        store.state.public_room.element &&
        store.state.public_room.muted_users.findIndex(
          (x) => x.id_user === data.data.user_sent.id_user
        ) !== -1

      if (store.state.random_chat.element) {
        if (
          store.state.auth.user.id === data.data.user_received.id &&
          !mutedSender
        ) {
          store.dispatch("setNewFriendRequest", {
            status: true,
            user_sent: data.data.user_sent
          })
        }
      }

      // Public room friend requests are handled through type_file -1 messages
    })

    socket.on("answer_friend_request", (data) => {
      console.log("answer_friend_request", data)
      if (data.data.status_request === 2) {
        if (
          store.state.auth.user.id ===
          data.data.follow_request_sent.id_user_sent
        ) {
          store.dispatch(
            "setFriendRequestAccepted",
            data.data.follow_request_sent.id_user_received
          )
          if (store.state.random_chat.element)
            store.dispatch("random_chat/friendRequestAccepted", {
              id_user: data.data.follow_request_sent.id_user_received
            })
          if (store.state.public_room.element)
            store.dispatch("public_room/friendRequestAccepted", {
              id_user_sent: data.data.follow_request_sent.id_user_sent,
              id_user_received: data.data.follow_request_sent.id_user_received,
              accepted: 1
            })
        }
        if (
          store.state.auth.user.id ===
          data.data.follow_request_sent.id_user_received
        ) {
          store.dispatch(
            "setFriendRequestAccepted",
            data.data.follow_request_sent.id_user_sent
          )
          if (store.state.random_chat.element)
            store.dispatch("random_chat/friendRequestAccepted", {
              id_user: data.data.follow_request_sent.id_user_sent
            })
          if (store.state.public_room.element)
            store.dispatch("public_room/friendRequestAccepted", {
              id_user_sent: data.data.follow_request_sent.id_user_sent,
              id_user_received: data.data.follow_request_sent.id_user_received,
              accepted: 1
            })
        }
      }
      if (data.data.status_request === 3) {
        if (
          store.state.auth.user.id ===
          data.data.follow_request_sent.id_user_received
        ) {
          if (store.state.public_room.element)
            store.dispatch("public_room/friendRequestAccepted", {
              id_user_sent: data.data.follow_request_sent.id_user_sent,
              id_user_received: data.data.follow_request_sent.id_user_received,
              accepted: 0
            })
        }
      }
    })

    socket.on("forbidden_words", (data) => {
      console.log("forbidden_words", data)
      store.dispatch("public_room/setForbiddenWordsCount", data.total_messages)
    })

    socket.on("user_banned", (data) => {
      console.log("user_banned", data)
      if (store.state.auth.user.id === data.user.id) {
        store.dispatch("public_room/setUserPublicRoom", data.user_public_room)
      }
    })

    socket.on("banned_from_public_room", (data) => {
      console.log("banned_from_public_room", data)
    })

    socket.on("change_username", (data) => {
      console.log("change_username", data)
      store.dispatch("public_room/update", {
        stateField: "user_public_room",
        field: "username",
        value: data.data.username
      })
    })

    /*     socket.on("new_banning_poll", (data) => {
      console.log("new_banning_poll", data)
      if (
        store.state.auth.user.id !== parseInt(data.data.id_user_banned) &&
        store.state.auth.user.id !== data.data.id_user_created
      ) {
        store.dispatch("public_room/newBanVotation", {
          user_ban_votation: data.data,
          status: true
        })
      }
    }) */

    socket.on("join_random_chats", (data) => {
      console.log("join_random_chats", data)
      store.dispatch("random_chat/setUserRandomChat", data.data)
      if (store.state.random_chat.element)
        store.dispatch("random_chat/setElement", null)
    })

    socket.on("leave_random_chats", (data) => {
      console.log("leave_random_chats", data)
      store.dispatch("random_chat/setElement", null)
      store.dispatch("random_chat/setElementUser", null)
      store.dispatch("random_chat/setElementMessages", [])
      store.dispatch("random_chat/setUserRandomChat", null)
    })

    socket.on("random_chat_assigned", (data) => {
      console.log("random_chat_assigned", data)
      if (!data.user_sent && !data.user_received) {
        /* Normal random chat flow */
        store.dispatch("random_chat/setElement", data.random_chat)
        store.dispatch("random_chat/setElementUser", data.random_user)
        socket.emit("random_chat_assigned", { token: data.random_chat })
        return
      }
      /* Random chat request from public room flow */
      if (
        data.user_sent.id_user === store.state.auth.user.id ||
        data.user_received.id_user === store.state.auth.user.id
      ) {
        const other_user =
          data.user_sent.id_user === store.state.auth.user.id
            ? data.user_received
            : data.user_sent
        store.dispatch("random_chat/setElement", data.random_chat)
        store.dispatch("random_chat/setElementUser", other_user)
        socket.emit("random_chat_assigned", { token: data.random_chat })
      }
    })

    socket.on("force_join_random_chats", (data) => {
      console.log("force_join_random_chats", data)
      socket.emit("force_join_random_chats", {
        id_user: store.state.auth.user.id
      })
    })

    socket.on("random_chat_message", (data) => {
      console.log("random_chat_message", data)
      let datax = data
      if (store.state.random_chat.element_user)
        datax.followed_by_you_status =
          store.state.random_chat.element_user.user.followed_by_you_status
      store.dispatch("random_chat/addChatMessage", datax)
      if (store.state.auth.user.id === data.user.id)
        store.dispatch("random_chat/clearText", true)
    })

    socket.on("answer_chat_request", (data) => {
      console.log("answer_chat_request", data)
      if (
        data.data.chat_request_sent.id_user_received ===
        store.state.auth.user.id
      ) {
        store.dispatch("public_room/updateChatRequestStatus", {
          id_user_sent: data.data.chat_request_sent.id_user_sent,
          id_user_received: data.data.chat_request_sent.id_user_received,
          accepted: data.data.status_request === 2 ? 1 : 0
        })
      }
    })

    socket.on("chat_typing", (data) => {
      console.log("chat_typing", { ...data.data, isTyping: 1 })
      store.dispatch("chat/chatTyping", { ...data.data, isTyping: 1 })
    })

    socket.on("room_typing", (data) => {
      console.log("room_typing", { ...data.data, isTyping: 1 })
      if (store.state.auth.user.id !== data.data.user.id)
        store.dispatch("room/chatTyping", { ...data.data, isTyping: 1 })
    })

    socket.on("new_chat", (data) => {
      console.log("new_chat", data)
      store.dispatch("chat/addItem", data)
      if (store.state.auth.user.id === parseInt(data.id_user_a))
        store.dispatch("chat/setElement", data)
    })

    socket.on("chat_stop_typing", (data) => {
      console.log("chat_stop_typing", { ...data.data, isTyping: 0 })
      store.dispatch("chat/chatTyping", { ...data.data, isTyping: 0 })
    })

    socket.on("room_stop_typing", (data) => {
      console.log("room_stop_typing", { ...data.data, isTyping: 0 })
      store.dispatch("room/chatTyping", { ...data.data, isTyping: 0 })
    })

    socket.on("chat_mark_as_read", (data) => {
      console.log("chat_mark_as_read", data)

      store.dispatch("chat/readMsgs", {
        chat: data.data.chat,
        id_user: store.state.auth.user.id
      })
    })

    socket.on("room_mark_as_read", (data) => {
      console.log("room_mark_as_read", data)

      store.dispatch("room/readMsgs", {
        room: data.data.room,
        id_user: store.state.auth.user.id
      })
    })

    socket.on("user_disconnected", (data) => {
      console.log("user_disconnected", data)
      store.dispatch("chat/handleUserConnection", data.data)

      if (
        store.state.random_chat.element_user &&
        store.state.random_chat.element_user.user.id === data.data.id
      ) {
        socket.emit("new_random_chat", {
          id_user: store.state.auth.user.id,
          id_user_chatted: store.state.random_chat.element_user.user.id
        })
        store.dispatch("random_chat/setElement", null)
        store.dispatch("random_chat/setElementUser", null)
        store.dispatch("random_chat/setElementMessages", [])
      }
    })

    socket.on("check_username", (data) => {
      console.log("check_username", data)
      store.dispatch("setUsernameCheck", data.data)
    })

    socket.on("find_by_username", (data) => {
      console.log("find_by_username", data)
      store.dispatch("user/setSuggestedUsernames", data.data)
    })

    socket.on("error", (data) => {
      console.log("error", data)
    })

    socket.on("disconnect", (data) => {
      console.log("disconnect", data)
      if (store.state.auth.user)
        socket.emit("user_connected", {
          id_user: store.state.auth.user.id
        })
    })

    socket.on("user_disabled", (data) => {
      console.log("user_disabled", data)
      store.dispatch("auth/disabledUser")
    })
  }
}

export default createWebSocketPlugin
