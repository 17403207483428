export default {
  getElement: (state) => {
    return state.element
  },
  getElementUser: (state) => {
    return state.element_user
  },
  getElementMessages: (state) => {
    return state.element_messages
  },
  getUserRandomChat: (state) => {
    return state.user_random_chat
  },
  getClearText: (state) => {
    return state.clear_text
  }
}
