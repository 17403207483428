export default {
  ADD_ITEM(state, item) {
    state.unpaginated_elements.push(item)
  },

  UPDATE_ITEM(state, item) {
    let userStoryIndex
    let storyIndex
    let userStory

    if (state.element.length > 0 && state.element[0].id_user === item.id_user) {
      storyIndex = state.element.findIndex((x) => x.id === item.id)
      if (storyIndex !== -1) {
        state.element[storyIndex] = item
      }
    }

    userStoryIndex = state.unpaginated_elements.findIndex(
      (x) => x.id == item.id_user
    )

    if (userStoryIndex !== -1) {
      userStory = { ...state.unpaginated_elements[userStoryIndex] }
      storyIndex = state.unpaginated_elements[userStoryIndex].stories.findIndex(
        (x) => x.id === item.id
      )

      if (storyIndex !== -1) {
        userStory[storyIndex] = item
        Object.assign(state.unpaginated_elements[userStoryIndex], userStory)
      }
    }
  },

  SET_ITEMS_NO_PAGINATE(state, items) {
    state.unpaginated_elements = items
  },
  ADD_USER_STORIES(state, item) {
    let index = state.unpaginated_elements.findIndex((x) => x.id === item.id)
    if (index !== -1) {
      Object.assign(state.unpaginated_elements[index], item)
    } else {
      state.unpaginated_elements.unshift(item)
    }
  },

  SET_ITEM(state, data) {
    state.element = data.stories
    let datax = { ...data }
    delete datax.stories
    state.element_user = datax
  },
  SET_LOAD_NEW(state, status) {
    state.load_new = status
  },

  MARK_AS_VIEWED(state, data) {
    let userStoryIndex
    let storyIndex
    let item

    if (
      state.element.length > 0 &&
      state.element[0].id_user === data.id_user_story
    ) {
      storyIndex = state.element.findIndex((x) => x.id === data.id_story)
      if (storyIndex !== -1) {
        state.element[storyIndex].viewed = 1
      }
    }

    userStoryIndex = state.unpaginated_elements.findIndex(
      (x) => x.id == data.id_user_story
    )

    if (userStoryIndex !== -1) {
      item = state.unpaginated_elements[userStoryIndex]
      storyIndex = item.stories.findIndex((x) => x.id === data.id_story)
      if (storyIndex !== -1) {
        item.stories[storyIndex].viewed = 1
        if (item.stories.every((x) => x.viewed === 1)) item.stories_viewed = 1
        Object.assign(state.unpaginated_elements[userStoryIndex], item)
      }
    }
  },
  DELETE_ELEMENT(state, data) {
    state.load_new = true
    let userStoryIndex

    if (state.element.length > 0 && state.element[0].id_user === data.id_user) {
      state.element = state.element.filter((x) => x.id !== data.id)
    }

    userStoryIndex = state.unpaginated_elements.findIndex(
      (x) => x.id == data.id_user
    )

    let element = { ...state.unpaginated_elements[userStoryIndex] }
    element.stories = element.stories.filter((x) => x.id !== data.id)
    if (element.stories.length === 0) {
      state.unpaginated_elements.splice(userStoryIndex, 1)
    } else {
      Object.assign(state.unpaginated_elements[userStoryIndex], element)
    }
  },
  SET_RESUMABLE_VIDEO(state, data) {
    state.resumable_video = data
  },

  CHANGE_PROGRESS_RESUMABLE_VIDEO(state, data) {
    state.resumable_video.progress = data
  }
}
