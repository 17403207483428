export default {
  setItem({ commit }, item) {
    commit("SET_ITEM", item)
  },
  updateElement({ commit }, data) {
    commit("UPDATE_ITEM", data)
  },
  setSuggestedElements({ commit }, data) {
    commit("SET_SUGGESTED_ITEMS", data)
  }
}
