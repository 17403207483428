export default (object) => {
  return Object.keys(object).reduce((formData, key) => {
    if (key !== "id")
      if (Array.isArray(object[key])) {
        for (let element of object[key]) formData.append(key + "[]", element)
      } else {
        formData.append(key, object[key])
      }

    return formData
  }, new FormData())
}
