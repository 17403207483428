export default {
  SET_ITEM(state, item) {
    state.element = item
  },

  SET_ELEMENT_MESSAGES(state, messages) {
    state.element_messages = messages
  },

  SET_ELEMENT_USER(state, user) {
    state.element_user = user
  },

  SET_USER_RANDOM_CHAT(state, user) {
    state.user_random_chat = user
  },

  ADD_MESSAGE(state, message) {
    if (message.id_random_chat) {
      if (state.element && state.element === message.id_random_chat) {
        state.element_messages.push(message)
      }
    }
  },
  CLEAR_TEXT(state, clear) {
    state.clear_text = clear
  },
  UPDATE_FIELD(state, { field, value }) {
    if (state.element) {
      let element = { ...state.element }
      element[field] = value
      Object.assign(state.element, element)
    }
  },
  FRIEND_REQUEST_ACCEPTED(state, { id_user }) {
    if (state.element_user && state.element_user.user.id === id_user) {
      state.element_user.user.followed_by_you_status = 2
      state.element_user.user.follows_you_status = 2
      state.element_user.updated_at = new Date()
    }
  }
}
