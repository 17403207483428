export default [
  {
    path: "/profile",
    name: "Profile", // If this changes, logic in ProfilePicture has to change
    component: () => import(/* webpackPrefetch: true */ /* webpackChunkName: "profile" */"@/views/user/SelfProfile.vue"),
    meta: {
      rule: "isGlobal",
      authRequired: true,
      pageTitle: "profile",
      icon: "/static/icons/profile.svg"
    }
  },
  {
    path: "/user/:id",
    name: "User Profile", // If this changes, logic in ProfilePicture has to change
    component: () => import(/* webpackPrefetch: true */ /* webpackChunkName: "profile" */"@/views/user/UserProfile.vue"),
    meta: {
      rule: "isGlobal",
      //authRequired: true,
      pageTitle: "profile",
      icon: "/static/icons/profile.svg"
    }
  }
]
