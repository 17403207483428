export default {
  SET_ITEMS(state, items) {
    state.elements = items
  },

  SET_ITEMS_NO_PAGINATE(state, items) {
    state.unpaginated_elements = items
  },

  SET_USER_PUBLIC_ROOM(state, data) {
    state.user_public_room = data
    state.updated_at = new Date()
  },
  ADD_ITEM(state, item) {
    state.unpaginated_elements.push(item)
    state.elements.push(item)
  },

  SET_ITEM(state, item) {
    state.element = item
  },

  SET_ELEMENT_MESSAGES(state, messages) {
    const now = new Date()
    state.element_messages = messages.filter(
      (x) =>
        x.type_file !== -1 ||
        (now.getTime() - new Date(x.created_at + "Z").getTime()) / 1000 < 60
    )
  },
  REMOVE_EXPIRED_MESSAGE(state, messageId) {
    const index = state.element_messages.findIndex((x) => x.id === messageId)
    if (index !== -1) state.element_messages.splice(index, 1)
  },

  ADD_MESSAGE(state, message) {
    let aux_index = -1
    if (!message.public_room) {
      if (state.element && state.element.id === message.id_public_room) {
        state.element_messages.push(message)
      } else {
        aux_index = state.unpaginated_elements.findIndex(
          (x) => x.id === message.id_public_room
        )
      }
    } else {
      if (state.element && state.element.id === message.public_room.id) {
        state.element_messages.push(message)
      } else {
        aux_index = state.unpaginated_elements.findIndex(
          (x) => x.id === message.id_public_room
        )
      }
    }

    if (message.public_room) {
      let unpaginated_index = state.unpaginated_elements.findIndex(
        (x) => x.id === message.public_room.id
      )
      let paginated_index = state.elements.findIndex(
        (x) => x.id === message.public_room.id
      )
      let selected_public_room
      if (unpaginated_index !== -1) {
        selected_public_room = state.unpaginated_elements[unpaginated_index]
        Object.assign(
          state.unpaginated_elements[unpaginated_index],
          selected_public_room
        )
      }
      if (paginated_index !== -1) {
        selected_public_room = state.elements[paginated_index]

        Object.assign(state.elements[paginated_index], selected_public_room)
      }
    }
  },
  CLEAR_TEXT(state, clear) {
    state.clear_text = clear
  },
  SET_FORBIDDEN_WORDS_COUNT(state, data) {
    state.forbidden_words_count = data
  },
  TOGGLE_USER_MUTE(state, { action, id_user, username, id_public_room }) {
    if (action == "MUTE")
      state.muted_users.push({
        id_user,
        username,
        id_public_room,
        muted_at: new Date().toISOString()
      })
    if (action === "UNMUTE") {
      const index = state.muted_users.findIndex(
        (x) => x.id_user === id_user && x.id_public_room === id_public_room
      )
      if (index !== -1) {
        state.muted_users.splice(index, 1)
      }
    }
  },
  UPDATE_FIELD(state, { stateField, field, value }) {
    if (state[stateField]) {
      let element = { ...state[stateField] }
      element[field] = value
      Object.assign(state[stateField], element)
    }
  },
  FRIEND_REQUEST_ACCEPTED(
    state,
    { id_user_sent, id_user_received, accepted = 0 }
  ) {
    if (state.element) {
      for (let message of state.element_messages) {
        if (
          message.id_user === id_user_sent ||
          message.id_user === id_user_received
        ) {
          message.followed_by_you_status = 2
          message.follows_you_status = 2
        }
      }
      const friend_request_index = state.element_messages.findIndex(
        (x) =>
          x.id_user === id_user_sent && x.id_message_reply === id_user_received
      )
      if (friend_request_index !== -1) {
        state.element_messages[friend_request_index].accepted = accepted
      }
    }
  },
  UPDATE_CHAT_REQUEST(state, { id_user_sent, id_user_received, accepted = 0 }) {
    const index = state.element_messages.findIndex(
      (x) =>
        x.id_user === id_user_sent &&
        x.id_message_reply === id_user_received &&
        x.message.includes("chat request")
    )
    if (index !== -1) state.element_messages[index].accepted = accepted
  }
  /* SET_NEW_USER_BAN_VOTATION(state, status) {
    state.new_user_ban_votation = status
  },
  SET_USER_BAN_VOTATION(state, data) {
    state.user_ban_votation = data

    if (state.element_messages.length && data) {
      const index = state.element_messages.findIndex(
        (x) => x.id_user === data.id_user_banned
      )
      if (index !== -1) {
        state.user_ban_votation.followed_by_you_status =
          state.element_messages[index].followed_by_you_status
      }
    }
  },
  SET_NEW_USER_BAN_VOTATION(state, status) {
    state.new_user_ban_votation = status
  },
  SET_BANNED_USER(state, data) {
    state.banned_user = data
  }, */
}
