export default {
  ADD_ITEM(state, item) {
    state.elements.push(item)
    state.unpaginated_elements.push(item)
  },

  SET_ITEM(state, item) {
    state.element = item
  },

  SET_ITEMS(state, items) {
    state.elements = items
  },
  SET_ITEMS_NO_PAGINATE(state, items) {
    state.unpaginated_elements = items
  },

  UPDATE_ITEM(state, data) {
    let unpaginated_index = state.unpaginated_elements.findIndex(
      (x) => x.id === data.id
    )
    let regular_index = state.elements.findIndex((x) => x.id === data.id)
    if (unpaginated_index !== -1)
      state.unpaginated_elements[unpaginated_index] = data

    if (regular_index !== -1) state.elements[regular_index] = data
  },
  SET_SUGGESTED_ITEMS(state, data) {
    state.suggested_elements = data
  }
}
