export default {
  getElement: (state) => {
    return state.element
  },
  getElementUser: (state) => {
    return state.element_user
  },
  getAllUnpaginated: (state) => {
    return state.unpaginated_elements
  },
  getUserPublicRoom: (state) => {
    return state.user_public_room
  },
  getElementMessages: (state) => {
    return state.element_messages
  },
  getClearText: (state) => {
    return state.clear_text
  },
  getMutedUsers: (state) => {
    return state.muted_users
  },
  getForbiddenWordsCount: (state) => {
    return state.forbidden_words_count
  }
  /*   getNewUserBanVotation: (state) => {
    return state.new_user_ban_votation
  },
  getUserBanVotation: (state) => {
    return state.user_ban_votation
  },
  getBannedUser: (state) => {
    return state.user_banned
  }, */
}
