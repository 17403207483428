export default {
  SET_USER(state, user) {
    state.user = user
  },

  SET_GOOGLE_USER(state, user) {
    state.googleUser = user
  },

  LOGIN(state, token) {
    state.isAuthenticated = true
    window.localStorage.setItem("access_token", token)
    state.googleUser = null
  },

  LOGOUT(state) {
    state.isAuthenticated = false
    state.userRole = "public"
    window.localStorage.removeItem("access_token")
    state.user = null
    state.googleUser = null
    state.OAuthPreferences = null
  },
  SET_IMAGE(state, image) {
    state.user.image_profile = image
  },
  SET_VERIFIED(state, verified) {
    state.user.verified = verified
  },
  SET_USER_LOGGED_ROLE(state, idRole) {
    state.userRole = state.roleEnum[idRole]
  },
  SET_ACCESS_TOKEN(state, accessToken) {
    state.accessToken = accessToken
  },
  SET_OAUTH_PREFERENCES(state, data) {
    state.OAuthPreferences = data
  },
  SET_NOTIFICATIONS(state, notifications) {
    state.notifications = notifications
  },
  VIEW_NOTIFICATION(state, id) {
    for (let group_index in state.notifications) {
      let match_index = state.notifications[
        group_index
      ].notifications.findIndex((x) => x.id === id)
      if (match_index !== -1) {
        state.notifications[group_index].notifications[match_index].viewed = 1
      }
    }
  },
  VIEW_ALL_NOTIFICATIONS(state) {
    state.notifications.forEach((group) => {
      group.notifications.forEach((notification) => {
        notification.viewed = 1
      })
    })
  },
  PUSH_NOTIFICATION(state, notification) {
    state.newNotification = notification
  },
  SHOW_NOTIFICATION(state, status) {
    state.showNotification = status
  },
  STORIES_AVAILABLE(state, data) {
    if (state.user) state.user.stories_available = data
  },
  STORY_VIEWED(state, data) {
    let stories = data.stories
    let index = stories.findIndex((x) => x.id === data.id_story)
    if (index !== -1) {
      stories[index].viewed = 1
    }
    if (stories.every((x) => x.viewed === 1)) state.user.stories_viewed = 1
  }
}
