import state from "./moduleChatState"
import actions from "./moduleChatActions"
import getters from "./moduleChatGetters"
import mutations from "./moduleChatMutations"

export default {
  namespaced: true,
  state: state,
  mutations: mutations,
  actions: actions,
  getters: getters
}
