const detectHashtagsAndUsernames = (item) => {
  if (!item) return item
  let text = item
  const words = item.split(" ")
  const hashtags = []
  const usernames = []
  words.forEach((x) => {
    if (x.startsWith("#")) hashtags.push(x)
    if (x.startsWith("@")) usernames.push(x)
  })
  hashtags.forEach((x) => {
    text = text.replace(
      x,
      `<span class='text-primary-1100 font-bold post-hashtag' style='cursor:pointer'>${x}</span>`
    )
  })
  usernames.forEach((x) => {
    text = text.replace(
      x,
      `<span class='text-primary-1100 font-bold post-username' style='cursor:pointer'>${x}</span>`
    )
  })
  return text
}

const detectUsernameInput = (text) => {
  if (!text)
    return {
      inputting_username: false,
      username: null
    }

  const words = text.split(" ")
  const usernames = []
  words.forEach((x) => {
    if (x.startsWith("@") && x.length > 1) usernames.push(removeAtSymbol(x))
  })
  if (usernames.length === 0)
    return {
      inputting_username: false,
      username: null
    }

  //Following condition only allows username search if last word is a username, if not, ignore the query
  if (
    usernames[usernames.length - 1] !== removeAtSymbol(words[words.length - 1])
  )
    return {
      inputting_username: false,
      username: null
    }

  return {
    inputting_username: true,
    username: usernames[usernames.length - 1]
  }
}

const removeAtSymbol = (text) => {
  let modifiedText = text
  if (!text) return ""
  if (text.indexOf("@") !== -1) {
    modifiedText = modifiedText.replace("@", "")
  }
  return modifiedText
}

export { detectHashtagsAndUsernames, detectUsernameInput, removeAtSymbol }
