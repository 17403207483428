export default {
  elements: [],
  unpaginated_elements: [],
  grouped_elements: [],
  grouped_elements2: [],
  grouped_elements3: [],
  comments: [],
  content_creator: [],
  load_new_posts: false,
  suggested_videos: [],
  resumable_video: null
}
