export default {
  getAllUnpaginated: (state) => {
    return state.unpaginated_users
  },
  getAllSuggested: (state) => {
    return state.suggested_elements
  },
  getAllSuggestedUsernames: (state) => {
    return state.suggested_usernames
  }
}
