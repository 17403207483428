import Vue from "vue"
import VueRouter from "vue-router"
import store from "@/store/store.js"

Vue.use(VueRouter)

import routesAuth from "./routes/auth.js"
import routesDashboard from "./routes/dashboard.js"
import routesUser from "./routes/user.js"
import routesChat from "./routes/chat.js"
import routesRadio from "./routes/radio.js"
import routesPost from "./routes/post.js"

const routes = [
  {
    path: "",
    component: () => import("@/views/TempHome.vue"),
    meta: {
      rule: "isPublic",
      authRequired: false
    }
  },
  {
    path: "",
    name: "Inicio",
    redirect: "/",
    component: () => import("../layout/main/Main.vue"),
    children: [
      routesDashboard,
      routesUser,
      routesRadio,
      routesChat,
      routesPost
    ].flat()
  },
  {
    path: "/",
    name: "Inicio",
    component: () => import("../layout/main/Main.vue"),
    children: [
      routesDashboard,
      routesUser,
      routesRadio,
      routesChat,
      routesPost
    ].flat()
  },
  {
    path: "/soundcloud",
    component: () =>
      import(
        /* webpackPrefetch: true */ /* webpackChunkName: "soundcloud" */ "@/views/SoundCloudTest.vue"
      ),
    meta: {
      rule: "isPublic",
      authRequired: false
    }
  },
  {
    path: "/spotify",
    component: () =>
      import(
        /* webpackPrefetch: true */ /* webpackChunkName: "spotify" */ "@/views/SpotifyTest.vue"
      ),
    meta: {
      rule: "isPublic",
      authRequired: false
    }
  },
  {
    path: "/auth",
    component: () => import("../views/auth/Index.vue"),
    children: [routesAuth].flat()
  },
  {
    path: "*",
    name: "404",
    component: () => import("../views/error/404.vue"),
    meta: {
      rule: "isGlobal"
    }
  },
  {
    path: "/nopost",
    name: "nopost",
    component: () => import("../views/error/NoPost.vue"),
    meta: {
      rule: "isGlobal"
    }
  },
  {
    path: "/403",
    name: "notfound",
    component: () => import("../views/error/403.vue"),
    meta: {
      rule: "*"
    }
  },
  {
    path: "/disabled",
    name: "disabled",
    component: () => import("../views/error/disabled.vue"),
    meta: {
      rule: "isGlobal"
    }
  }
]

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes
})

router.beforeEach((to, from, next) => {
  if (to.meta.authRequired) {
    if (!store.state.auth.isAuthenticated) {
      router.push({ path: "/login" })
    }
  } else {
    if (store.state.auth.isAuthenticated) {
      if (
        to.path === "/login" ||
        to.path === "/signup" ||
        to.path === "/reset_password"
      ) {
        router.push({ path: "/home" })
      }
    }
  }

  return next()
})

export default router
