import Vue from "vue"
import { AclInstaller, AclCreate, AclRule } from "vue-acl"
import router from "@/router/index.js"
import store from "@/store/store.js"

Vue.use(AclInstaller)

let initialRole = "public"

const userRole = store.state.auth.userRole
if (userRole) initialRole = userRole

const acl = new AclCreate({
  initial: initialRole,
  notfound: "/403",
  router,
  acceptLocalRules: true,
  globalRules: {
    isUser: new AclRule("user").generate(),
    isAdmin: new AclRule("admin").generate(),
    isPremium: new AclRule("premium_user").generate(),
    isPublic: new AclRule("public").generate(),
    isGlobal: new AclRule("user")
      .or("admin")
      .or("public")
      .or("premium_user")
      .generate()
  }
})

export default acl
