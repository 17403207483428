export default {
  roles: {
    1: "Administrador",
    2: "Usuario"
  },
  status: {
    0: "Desactivado",
    1: "Activo",
    2: "Eliminado por sistema"
  },
  gender: {
    0: "No definido",
    1: "Femenino",
    2: "Masculino"
  },
  theme: "light",
  searchModal: false,
  searchQuery: "",
  usernameCheck: null,
  new_friend_request: false,
  friend_request_sender: null
}
