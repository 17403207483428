import Vue from "vue"
import VueTailwind from "vue-tailwind"
import TInput from "vue-tailwind/dist/t-input"
import TTextarea from "vue-tailwind/dist/t-textarea"
import TSelect from "vue-tailwind/dist/t-select"
import TRadio from "vue-tailwind/dist/t-radio"
import TCheckbox from "vue-tailwind/dist/t-checkbox"
import TButton from "vue-tailwind/dist/t-button"
import TInputGroup from "vue-tailwind/dist/t-input-group"
import TRichSelect from "vue-tailwind/dist/t-rich-select"
import TToggle from "vue-tailwind/dist/t-toggle"
import TCard from "vue-tailwind/dist/t-card"
import TModal from "vue-tailwind/dist/t-modal"
import TDropdown from "vue-tailwind/dist/t-dropdown"

const settings = {
  "t-input": {
    component: TInput,
    props: {
      fixedClasses:
        "block w-full px-3 py-2 transition duration-100 ease-in-out border border-1 rounded-lg shadow-sm focus:border-primary-800 focus:border-2 focus:shadow-md focus:outline-none disabled:opacity-50 disabled:cursor-not-allowed",
      classes:
        "text-black placeholder-gray-400 bg-white border-gray-300 dark:bg-gray-800 dark:text-white",
      variants: {
        danger: "border-red-300 bg-red-50 placeholder-gray-400 text-red-900",
        success:
          "border-green-300 bg-green-50 placeholder-gray-400 text-green-900",
        warning:
          "border-yellow-500 bg-yellow-50 placeholder-gray-400 text-yellow-900"
      }
    }
  },
  "t-textarea": {
    component: TTextarea,
    props: {
      fixedClasses:
        "block w-full px-3 py-2 transition duration-100 ease-in-out border rounded-lg shadow-sm focus:border-primary-800  focus:ring-2 focus:ring-primary-800 focus:outline-none focus:ring-opacity-50 disabled:opacity-50 disabled:cursor-not-allowed",
      classes:
        "text-black placeholder-gray-400 bg-white dark:bg-gray-800 dark:text-white border-gray-300",
      variants: {
        danger: "border-red-300 bg-red-50 placeholder-red-200 text-red-900",
        success:
          "border-green-300 bg-green-50 placeholder-gray-400 text-green-900",
        warning:
          "border-yellow-500 bg-yellow-50 placeholder-gray-400 text-yellow-900"
      }
    }
  },
  "t-select": {
    component: TSelect,
    props: {
      fixedClasses:
        "block w-full pl-3 pr-10 py-2 transition duration-100 ease-in-out border border-1 rounded-lg shadow-sm focus:border-green-2000 focus:border-2 focus:shadow-md focus:outline-none disabled:opacity-50 disabled:cursor-not-allowed",
      classes: "text-black placeholder-gray-400 bg-white border-gray-300",
      variants: {
        danger: "border-red-300 bg-red-50 placeholder-red-200 text-red-900",
        success:
          "border-green-300 bg-green-50 placeholder-gray-400 text-green-900",
        warning:
          "border-yellow-300 bg-yellow-50 placeholder-gray-400 text-yellow-900"
      }
    }
  },
  "t-radio": {
    component: TRadio,
    props: {
      fixedClasses:
        "transition duration-100 ease-in-out shadow-sm focus:border-primary-1100 text-xl focus:ring-primary-1100 focus:outline-none focus:ring-opacity-50 focus:ring-offset-0 disabled:opacity-50 disabled:cursor-not-allowed",
      classes: "text-primary-1100 border-primary-1100",
      variants: {
        error: "text-red-500 border-red-300",
        success: "text-green-500 border-green-300",
        warning: "text-yellow-500 border-gray-300"
      }
    }
  },
  "t-checkbox": {
    component: TCheckbox,
    props: {
      fixedClasses:
        "transition duration-100 ease-in-out rounded shadow-sm focus:border-green-500 focus:ring-2 focus:ring-green-500 focus:outline-none focus:ring-opacity-50 focus:ring-offset-0 disabled:opacity-50 disabled:cursor-not-allowed",
      classes: "text-green-500 border-gray-300 ",
      variants: {
        error: "text-red-500 border-red-300",
        success: "text-green-500 border-green-300",
        warning: "text-yellow-500 border-gray-300 "
      }
    }
  },
  "t-button": {
    component: TButton,
    props: {
      fixedClasses:
        "flex justify-center transition duration-100 ease-in-out focus:outline-none disabled:opacity-50 disabled:cursor-not-allowed shadow-sm rounded-lg items-center",
      classes:
        "px-4 py-2 text-white bg-primary-1100 border-0 hover:bg-primary-1000 focus:ring-primary-1000",
      variants: {
        sm: "px-2 py-1 text-sm text-white bg-primary-1100 border-0 hover:bg-primary-1000 focus:ring-primary-1000",
        empty: "",
        error:
          "px-4 py-2 text-white bg-red-500 border border-transparent rounded shadow-sm hover:bg-red-600 focus:ring-red-500",
        gray: "px-4 py-2 text-white bg-gray-500 border border-transparent rounded shadow-sm hover:bg-gray-600 focus:ring-gray-500",
        "gray-sm":
          "px-2 py-1 text-sm bg-gray-500 border border-transparent text-white rounded shadow-sm hover:bg-gray-600 focus:ring-gray-500",

        hashtag:
          "px-4 py-2 text-primary-1100 font-bold bg-gray-300 dark:bg-gray-200 dark:bg-opacity-25 text-white border border-transparent rounded-full shadow-sm hover:bg-gray-400 dark:hover:bg-gray-400 focus:ring-gray-500",
        success:
          "px-4 py-2 text-white bg-green-500 border border-transparent rounded shadow-sm hover:bg-green-600 focus:ring-green-500",
        orange:
          "px-4 py-2 text-white bg-orange-primary border border-transparent rounded shadow-sm hover:bg-orange-primary focus:ring-orange-primary",
        warning:
          "px-4 py-2 text-white bg-yellow-500 border border-transparent rounded shadow-sm hover:bg-yellow-600 focus:ring-yellow-500",
        link: "text-primary-1100 font-bold hover:text-primary-1200 shadow-none focus:ring-0 p-0",
        "outline-danger":
          "px-4 py-2 bg-transparent border border-red-500 text-red-500 rounded shadow-sm hover:bg-red-500 hover:text-white focus:bg-red-500 focus:text-white",
        "outline-warning":
          "px-4 py-2 bg-transparent border border-yellow-500 text-yellow-500 rounded shadow-sm hover:bg-yellow-500 hover:text-white focus:bg-yellow-500 focus:text-white",
        "outline-orange":
          "px-4 py-2 bg-transparent border border-orange-primary text-orange-primary rounded shadow-sm hover:bg-orange-primary hover:text-white focus:bg-orange-primary focus:text-white",
        "error-sm":
          "px-2 py-1 text-sm text-white bg-red-500 border border-transparent rounded shadow-sm hover:bg-red-600 focus:ring-red-500",
        "warning-sm":
          "px-2 py-1 text-sm text-white bg-yellow-500 border border-transparent rounded shadow-sm hover:bg-yellow-600 focus:ring-yellow-500",
        "danger-sm":
          "px-2 py-1 text-sm bg-red-500 border border-red-500 text-white rounded shadow-sm",
        "outline-danger-sm":
          "px-2 py-1 text-sm bg-transparent border border-red-500 text-red-500 rounded shadow-sm hover:bg-red-500 hover:text-white focus:bg-red-500 focus:text-white",
        "outline-warning-sm":
          "px-2 py-1 text-sm bg-transparent border border-yellow-500 text-yellow-500 rounded shadow-sm hover:bg-yellow-500 hover:text-white focus:bg-yellow-500 focus:text-white",
        "outline-gray-sm":
          "px-2 py-1 text-sm bg-transparent border border-gray-500 text-white rounded shadow-sm hover:bg-gray-500 focus:bg-gray-500"
      }
    }
  },
  "t-input-group": {
    component: TInputGroup,
    props: {
      fixedClasses: {
        label: "block",
        feedback: " text-sm text-sm w-fit px-2 ml-2 mb-2",
        description: "text-gray-400 text-sm"
      },
      classes: {
        wrapper: "",
        label: "",
        body: "",
        feedback: "text-gray-400",
        description: "text-gray-400"
      },
      variants: {
        danger: {
          label: "text-red-500",
          feedback: "bg-red-500 text-white rounded-lg rounded-t-none ",
          wrapper: "",
          body: ""
        },
        success: {
          label: "text-green-500",
          feedback: "bg-green-500 text-white rounded-lg rounded-t-none "
        },
        warning: {
          wrapper: "",
          label: "text-yellow-700",
          body: "",
          feedback:
            "border-yellow-300 bg-yellow-300 placeholder-gray-400 text-yellow-700 border rounded-lg rounded-t-none border-t-0 ",
          description: "text-gray-400"
        }
      }
    }
  },
  "t-rich-select": {
    component: TRichSelect,
    props: {
      fixedClasses: {
        wrapper: "relative",
        buttonWrapper: "inline-block relative w-full",
        selectButton:
          "w-full flex text-left justify-between items-center px-3 py-2 text-black transition duration-100 ease-in-out border rounded shadow-sm focus:border-blue-500 focus:ring-2 focus:ring-blue-500 focus:outline-none focus:ring-opacity-50 disabled:opacity-50 disabled:cursor-not-allowed",
        selectButtonLabel: "block truncate",
        selectButtonPlaceholder: "block truncate",
        selectButtonIcon: "fill-current flex-shrink-0 ml-1 h-4 w-4",
        selectButtonClearButton:
          "rounded flex flex-shrink-0 items-center justify-center absolute right-0 top-0 m-2 h-6 w-6 transition duration-100 ease-in-out",
        selectButtonClearIcon: "fill-current h-3 w-3",
        dropdown:
          "absolute w-full z-10 -mt-1 border-b border-l border-r rounded-b shadow-sm",
        dropdownFeedback: "",
        loadingMoreResults: "",
        optionsList: "overflow-auto",
        searchWrapper: "inline-block w-full",
        searchBox: "inline-block w-full",
        optgroup: "",
        option: "cursor-pointer",
        disabledOption: "opacity-50 cursor-not-allowed",
        highlightedOption: "cursor-pointer",
        selectedOption: "cursor-pointer",
        selectedHighlightedOption: "cursor-pointer",
        optionContent: "flex justify-between items-center px-3 py-2",
        optionLabel: "truncate block",
        selectedIcon: "fill-current h-4 w-4",
        enterClass: "",
        enterActiveClass: "",
        enterToClass: "",
        leaveClass: "",
        leaveActiveClass: "",
        leaveToClass: ""
      },
      classes: {
        wrapper: "",
        buttonWrapper: "",
        selectButton: "bg-white border-gray-300",
        selectButtonLabel: "",
        selectButtonPlaceholder: "text-gray-400",
        selectButtonIcon: "text-gray-600",
        selectButtonClearButton: "hover:bg-blue-100 text-gray-600",
        selectButtonClearIcon: "",
        dropdown: "bg-white border-gray-300",
        dropdownFeedback: "pb-2 px-3 text-gray-400 text-sm",
        loadingMoreResults: "pb-2 px-3 text-gray-400 text-sm",
        optionsList: "",
        searchWrapper: "p-2 placeholder-gray-400",
        searchBox:
          "px-3 py-2 bg-gray-50 text-sm rounded border focus:outline-none focus:shadow-outline border-gray-300",
        optgroup: "text-gray-400 uppercase text-xs py-1 px-2 font-semibold",
        option: "",
        disabledOption: "",
        highlightedOption: "bg-blue-100",
        selectedOption:
          "font-semibold bg-gray-100 bg-blue-500 font-semibold text-white",
        selectedHighlightedOption:
          "font-semibold bg-gray-100 bg-blue-600 font-semibold text-white",
        optionContent: "flex justify-between items-center px-3 py-2",
        optionLabel: "",
        selectedIcon: "",
        enterClass: "opacity-0",
        enterActiveClass: "transition ease-out duration-100",
        enterToClass: "opacity-100",
        leaveClass: "opacity-100",
        leaveActiveClass: "transition ease-in duration-75",
        leaveToClass: "opacity-0"
      },
      variants: {
        danger: {
          selectButton: "border-red-300 bg-red-50 text-red-900",
          selectButtonPlaceholder: "text-red-200",
          selectButtonIcon: "text-red-500",
          selectButtonClearButton: "hover:bg-red-200 text-red-500",
          dropdown: "bg-red-50 border-red-300 "
        },
        success: {
          selectButton: "border-green-300 bg-green-50 text-green-900",
          selectButtonIcon: "text-green-500",
          selectButtonClearButton: "hover:bg-green-200 text-green-500",
          dropdown: "bg-green-50 border-green-300"
        },
        dynamic: {
          selectButton:
            "text-black placeholder-gray-400 bg-white dark:bg-gray-800 dark:text-white border-gray-300",

          selectButtonLabel: "text-black dark:text-white",
          dropdown:
            "text-black bg-white dark:bg-gray-800 dark:text-white border-gray-300",
          selectButtonPlaceholder: "text-black dark:text-white",
          selectedOption:
            "bg-blue-100 dark:bg-gray-500 text-black dark:text-white",
          highlightedOption: "bg-blue-100 dark:bg-gray-500",
          selectedHighlightedOption:
            "bg-blue-200 dark:bg-gray-400 text-black dark:text-white",
          searchBox:
            "px-3 py-2 bg-gray-50 text-sm rounded border focus:outline-none focus:shadow-outline border-gray-300 text-black",
          selectButtonIcon: "text-black dark:text-white",
          selectButtonClearButton: "text-black dark:text-white",
          dropdownFeedback: "text-black dark:text-white",
          loadingMoreResults: "text-black dark:text-white",
          optgroup: "text-black dark:text-white",

          optionLabel: "text-black dark:text-white"
        }
      }
    }
  },
  "t-toggle": {
    component: TToggle,
    props: {
      fixedClasses: {
        wrapper:
          "relative inline-flex flex-shrink-0 cursor-pointer transition-colors ease-in-out duration-200",
        wrapperChecked:
          "relative inline-flex flex-shrink-0 cursor-pointer transition-colors ease-in-out duration-200  border-2 border-transparent focus:ring-2 focus:ring-primary-1100 focus:outline-none focus:ring-opacity-50",
        wrapperDisabled:
          "relative inline-flex flex-shrink-0 cursor-pointer transition-colors ease-in-out duration-200 opacity-50 cursor-not-allowed",
        wrapperCheckedDisabled:
          "relative inline-flex flex-shrink-0 cursor-pointer transition-colors ease-in-out duration-200 opacity-50 cursor-not-allowed border-2 border-transparent focus:ring-2 focus:ring-blue-500 focus:outline-none focus:ring-opacity-50",
        button:
          "inline-block absolute transform translate-x-0 transition ease-in-out duration-200",
        buttonChecked:
          "inline-block absolute transform translate-x-full transition ease-in-out duration-200",
        checkedPlaceholder: "inline-block",
        uncheckedPlaceholder: "inline-block"
      },
      classes: {
        wrapper:
          "bg-gray-200 rounded-full border-2 border-transparent focus:ring-2 focus:ring-primary-1100 focus:outline-none focus:ring-opacity-50",
        wrapperChecked: "bg-primary-1100 rounded-full",
        wrapperDisabled:
          "bg-gray-100 rounded-full border-2 border-transparent focus:ring-2 focus:ring-blue-500 focus:outline-none focus:ring-opacity-50",
        wrapperCheckedDisabled: "bg-blue-500",
        button:
          "h-5 w-5 rounded-full bg-white shadow flex items-center justify-center text-gray-400 text-xs",
        buttonChecked:
          "h-5 w-5 rounded-full bg-white shadow flex items-center justify-center text-blue-500 text-xs",
        checkedPlaceholder:
          "rounded-full w-5 h-5 flex items-center justify-center text-gray-400 text-xs",
        uncheckedPlaceholder:
          "rounded-full w-5 h-5 flex items-center justify-center text-gray-400 text-xs"
      },
      variants: {
        danger: {
          wrapperChecked: "bg-red-500 rounded-full",
          wrapperCheckedDisabled: "bg-red-500 rounded-full"
        },
        success: {
          wrapperChecked: "bg-green-500 rounded-full",
          wrapperCheckedDisabled: "bg-green-500 rounded-full"
        },
        box: {
          wrapper:
            "bg-gray-100 rounded-sm border-2 border-transparent focus:ring-2 focus:ring-blue-500 focus:outline-none focus:ring-opacity-50",
          wrapperChecked: "bg-blue-500 rounded-sm",
          wrapperCheckedDisabled: "bg-blue-500 rounded-sm",
          button:
            "h-6 w-6 rounded-sm bg-white shadow flex items-center justify-center text-gray-400 text-xs",
          buttonChecked:
            "h-6 w-6 rounded-sm  bg-white shadow flex items-center justify-center text-blue-500 text-xs",
          checkedPlaceholder:
            "rounded-sm w-6 h-6 flex items-center justify-center text-gray-400 text-xs",
          uncheckedPlaceholder:
            "rounded-sm w-6 h-6 flex items-center justify-center text-gray-400 text-xs"
        }
      }
    }
  },
  "t-card": {
    component: TCard,
    props: {
      fixedClasses: {
        wrapper: "rounded-xl shadow-md",
        body: "h-full",
        header: "font-medium tracking-tighter text-lg p-2 rounded-t",
        footer: "p-2 rounded-b"
      },
      classes: {
        wrapper: "bg-white border-gray-100",
        body: "p-3",
        header: "border-gray-100",
        footer: "border-gray-100"
      },
      variants: {
        danger: {
          wrapper: "bg-red-50 text-red-700 border-red-200",
          header: "border-red-200 text-red-700",
          footer: "border-red-200 text-red-700"
        },
        light: {
          wrapper: "bg-white text-black",
          header: "text-black ",
          footer: "text-black "
        },
        "jevi-dark": {
          wrapper: "bg-jevi-dark border-gray-100",
          body: "text-white",
          header: "border-gray-100 text-white",
          footer: "border-gray-100 text-white"
        },
        dark: {
          wrapper: "bg-translucid-black border-gray-100",
          body: "text-white",
          header: "border-gray-100 text-white",
          footer: "border-gray-100 text-white"
        },
        "dark-story": {
          wrapper: "bg-translucid-black border-gray-100",
          body: "text-white p-0",
          header: "border-gray-100 text-white",
          footer: "border-gray-100 text-white"
        },
        "light-story": {
          wrapper: "bg-white text-black",
          body: "p-0",
          header: "text-black ",
          footer: "text-black "
        }
      }
    }
  },
  "t-modal": {
    component: TModal,
    props: {
      fixedClasses: {
        overlay:
          "z-40 overflow-auto scrolling-touch left-0 top-0 bottom-0 right-0 fixed",
        wrapper: "relative mx-auto z-50",
        modal: "overflow-visible relative rounded",
        body: "",
        header: "p-3 rounded-t dark:text-white text-left",
        footer: " p-3 rounded-b",
        close:
          "flex items-center justify-center rounded-full absolute right-0 top-0 -m-3 h-8 w-8 transition duration-100 ease-in-out focus:ring-2 focus:ring-primary-500 focus:outline-none focus:ring-opacity-50"
      },
      classes: {
        overlay: "bg-gray-800 bg-opacity-50 w-full h-full",
        wrapper: "px-3 py-1",
        modal: "bg-white dark:bg-jevi-dark shadow",
        body: "p-3 text-gray-900 dark:text-white",
        header: "text-black dark:text-white text-lg",
        footer: "bg-white dark:bg-jevi-dark text-gray-800 dark:text-white",
        close:
          "text-black dark:text-white right-4 top-4",
        closeIcon: "fill-current h-4 w-4",
        overlayEnterClass: "opacity-0",
        overlayEnterActiveClass: "transition ease-out duration-100",
        overlayEnterToClass: "opacity-100",
        overlayLeaveClass: "opacity-100",
        overlayLeaveActiveClass: "transition ease-in duration-75",
        overlayLeaveToClass: "opacity-0",
        enterClass: "",
        enterActiveClass: "",
        enterToClass: "",
        leaveClass: "",
        leaveActiveClass: "",
        leaveToClass: ""
      },
      variants: {
        danger: {
          overlay: "bg-red-900",
          header: "border-red-100 text-red-500 text-xl font-bold",
          close:
            "bg-red-50 text-red-700 hover:bg-red-200 border-red-100 border right-4 top-4",
          modal: "bg-white border border-red-100 shadow-lg",
          footer: "bg-red-50"
        },
        post: {
          header: "border-opacity-0",
          wrapper: "max-w-5xl max-h-7/10"
        },
        story: {
          overlay: "bg-opacity-0 w-screen h-screen",
          header: "hidden",
          wrapper: "py-0",
          close:
            "bg-primary-1100 dark:bg-white text-white dark:text-black right-4 top-4 z-20",
          body: "p-0 text-gray-900 dark:text-white min-w-screen min-h-screen"
        },
        "3xl": {
          wrapper: "max-w-3xl min-h-80vh"
        },
        md: {
          wrapper: "max-w-md"
        },
        lg: {
          wrapper: "max-w-lg"
        },
        xl: {
          wrapper: "max-w-xl"
        }
      }
    }
  },
  "t-dropdown": {
    component: TDropdown,
    props: {
      fixedClasses: {
        button:
          "flex items-center text-white block px-4 py-2 transition duration-100 ease-in-out rounded-full border border-transparent rounded shadow-sm focus:outline-none focus:ring-opacity-50 disabled:opacity-50 disabled:cursor-not-allowed",
        wrapper: "inline-flex flex-col ",
        dropdownWrapper: "relative z-10",
        dropdown: "absolute rounded shadow-md mt-1",
        enterClass: "opacity-0 scale-95",
        enterActiveClass: "transition transform ease-out duration-100",
        enterToClass: "opacity-100 scale-100",
        leaveClass: "opacity-100 scale-100",
        leaveActiveClass: "transition transform ease-in duration-75",
        leaveToClass: "opacity-0 scale-95"
      },
      classes: {
        button:
          "bg-green-500 hover:bg-green-600 focus:border-green-500 focus:ring-2 focus:ring-green-500",
        dropdown: "bg-white w-56 left-auto right-0 top-3 mt-3",
        enterClass: "",
        enterActiveClass: "",
        enterToClass: "",
        leaveClass: "",
        leaveActiveClass: "",
        leaveToClass: "",
        dropdownWrapper: "",
        wrapper: ""
      },
      variants: {
        danger: {
          button: "bg-red-500 hover:bg-red-600",
          dropdown: "bg-red-100",
          enterActiveClass: ""
        },
        tooltip: {
          dropdown:
            "bg-green-600 bottom-0 left-6 right-auto text-white text-xs rounded-md p-2 w-28 mt-0",
          enterActiveClass: ""
        },
        tooltipTop: {
          dropdown:
            "bottom-6 top-auto text-white text-xs rounded-md p-2 w-28 mt-0",
          enterActiveClass: ""
        },
        tooltipLeft: {
          dropdown:
            "bg-green-600 bottom-0 left-auto right-6 text-white text-xs rounded-md p-2 w-28 mt-0",
          enterActiveClass: ""
        }
      }
    }
  }
}

Vue.use(VueTailwind, settings)
