import state from "./moduleRoomState"
import actions from "./moduleRoomActions"
import getters from "./moduleRoomGetters"
import mutations from "./moduleRoomMutations"

export default {
  namespaced: true,
  state: state,
  mutations: mutations,
  actions: actions,
  getters: getters
}
