import isMobilePhone from "validator/es/lib/isMobilePhone"
import store from "../store/store"

let browser_lang =
  navigator.language && ["es", "en"].includes(navigator.language.split("-")[0])
    ? navigator.language.split("-")[0]
    : "es"

if (store.state.auth.user) browser_lang = store.state.auth.user.lang

export const es = {
  es: {
    attributes: {
      name: "nombre",
      first_name: "nombre",
      last_name: "apellido",
      telephone: "teléfono",
      address: "Dirección",
      email: "correo electrónico",
      password: "contraseña",
      passwordConfirm: "confirmar contraseña",
      code: "código de verificación",
      old_password: "contraseña actual",
      new_password: "nueva contraseña",
      address: "dirección",
      birthdate: "fecha de nacimiento",
      phone: "teléfono",
      gender: "género",
      description: "descripción",
      date: "fecha",
      hour: "hora",
      postVideo: "video"
    },
    messages: {
      confirmed: () => "Las contraseñas no coinciden"
    }
  }
}

export const en = {
  en: {
    attributes: {
      name: "name",
      first_name: "name",
      last_name: "last name",
      telephone: "phone",
      address: "address",
      email: "email",
      password: "password",
      passwordConfirm: "password confirmation",
      code: "code",
      old_password: "old password",
      new_password: "new password",
      birthdate: "birthdate",
      phone: "phone",
      gender: "gender",
      date: "date",
      hour: "hour",
      postVideo: "video"
    },
    messages: {
      confirmed: () => "Passwords do not match"
    }
  }
}

export const alpha_spaces_rule = {
  getMessage: (field) =>
    browser_lang == "es"
      ? `El campo ${field} solo debe contener letras y espacios.`
      : `The field ${field} must only contain letters and spaces.`,
  validate: (value) => /^[A-Záéíóúñ' ]+$/i.test(value)
}

export const youtube_url = {
  getMessage: () =>
    browser_lang == "es"
      ? `El campo debe contener una URL válida de Youtube`
      : `The field must contain a valid Youtube URL`,
  validate: (value) =>
    /^(?:https?:\/\/)?(?:m\.|www\.)?(?:youtu\.be\/|youtube\.com\/(?:embed\/|v\/|watch\?v=|watch\?.+&v=))((\w|-){11})(?:\S+)?$/.test(
      value
    )
}

export const simple_required = {
  getMessage: (field) =>
    browser_lang == "es" ? `Este campo es requerido` : `This field is required`,
  validate: (value) => {
    return {
      valid: ["", null, undefined].indexOf(value) === -1,
      data: {
        required: true // or false
      }
    }
  }
}
export const simple_max = {
  getMessage: (field, maxLength) =>
    browser_lang == "es"
      ? `Este campo no puede exceder los ${maxLength} caracteres`
      : `This field cannot exceed ${maxLength} characters`,
  validate: (value, { maxLength }) => value.length < parseInt(maxLength)
}

export const alpha_symbol_rule = {
  getMessage: (field) =>
    browser_lang == "es"
      ? `El campo ${field} solo debe contener letras, espacios y los símbolos: ( ) /`
      : `The field ${field} must only contain letters, spaces and symbols ( ) /`,
  validate: (value) => /^[A-Záéíóúñ'()/ ¿?]+$/i.test(value)
}

export const username_rule = {
  getMessage: (field) =>
    browser_lang == "es"
      ? `El campo ${field} solo debe contener letras minúsculas, puntos y guiones bajos`
      : `The field ${field} must only contain lowercase letters, dots and underscores`,
  validate: (value) => /^[a-z._0-9]+$/.test(value)
}

export const phone_rule = {
  getMessage() {
    return browser_lang == "es"
      ? `El campo debe ser un teléfono válido.`
      : `The field must contain a valid phone`
  },
  validate(value) {
    return isMobilePhone(value)
  }
}
