export default {
  getRole: (state) => (id) => {
    return state.roles[id]
  },
  getStatus: (state) => (id) => {
    return state.status[id]
  },
  getGender: (state) => (id) => {
    return state.gender[id]
  },
  getTheme: (state) => {
    return state.theme
  },
  getSearchModal: (state) => {
    return state.searchModal
  },
  getSearchQuery: (state) => {
    return state.searchQuery
  },
  getUsernameCheck: (state) => {
    return state.usernameCheck
  },
  getNewFriendRequest: (state) => {
    return state.new_friend_request
  },
  getFriendRequestSender: (state) => {
    return state.friend_request_sender
  }
}
