import Vue from "vue"

import TLoader from "./components/Loader.vue"
import TUpload from "./components/Upload.vue"
import HeaderUpload from "./components/HeaderUpload.vue"
import TUploadFile from "./components/UploadFile.vue"
import ChatUpload from "./components/ChatUpload.vue"

Vue.component(TLoader.name, TLoader)
Vue.component(TUpload.name, TUpload)
Vue.component(TUploadFile.name, TUploadFile)
Vue.component(ChatUpload.name, ChatUpload)
Vue.component(HeaderUpload.name, HeaderUpload)
