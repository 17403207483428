export default {
  ADD_ITEM(state, item) {
    state.users.push(item)
  },

  UPDATE_ITEM(state, item) {
    let index = state.users.findIndex((index) => index.id == item.id)
    Object.assign(state.users[index], item)
  },

  SET_ITEMS(state, items) {
    state.users = items
  },

  SET_ITEMS_NO_PAGINATE(state, items) {
    state.unpaginated_users = items
  },

  SET_ITEM(state, item) {
    state.user = item
  },

  SET_SUGGESTED_ITEMS(state, data) {
    state.suggested_elements = data
  },
  UPDATE_SUGGESTED_ITEMS(state, data) {
    let index = state.suggested_elements.findIndex(
      (index) => index.id == data.id
    )
    if (index !== -1) Object.assign(state.suggested_elements[index], data)
  },
  SET_SUGGESTED_USERNAMES(state, data) {
    state.suggested_usernames = data
  }
}
