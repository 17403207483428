export default {
  elements: [],
  element: null,
  user_public_room: null,
  unpaginated_elements: [],
  element_messages: [],
  clear_text: false,
  muted_users: [],
  forbidden_words_count: null
  /*   new_user_ban_votation: false,
  user_ban_votation: null,
  banned_user: null, */
}
