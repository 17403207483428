import SocketIO from "socket.io-client"
var url_domain = window.location.host

// Default URL
var config_url = "https://apitest.jeviapp.com"

if (url_domain == "app.jeviapp.com") config_url = "https://api.jeviapp.com"

const socket = SocketIO(config_url, {
  transports: ["websocket"]
})

export default socket
