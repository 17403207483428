export default [
  {
    path: "/radio",
    name: "Radios",
    component: () => import(/* webpackPrefetch: true */ /* webpackChunkName: "radio" */"@/views/radio/Index.vue"),
    meta: {
      rule: "isGlobal",
      authRequired: true,
      pageTitle: "radio",
      icon: "/static/icons/radio.svg"
    }
  }
]
