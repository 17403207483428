import state from "./moduleHighlightState"
import actions from "./moduleHighlightActions"
import getters from "./moduleHighlightGetters"
import mutations from "./moduleHighlightMutations"

export default {
  namespaced: true,
  state: state,
  mutations: mutations,
  actions: actions,
  getters: getters
}
