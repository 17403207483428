export default {
  getUser: (state) => {
    return state.user
  },
  getOAuthPreferences: (state) => {
    return state.OAuthPreferences
  },
  getGoogleUser: (state) => {
    return state.googleUser
  },
  getNotifications: (state) => {
    return state.notifications
  },
  getShowNotification: (state) => {
    return state.showNotification
  },
  getNewNotification: (state) => {
    return state.newNotification
  },
  countNotificationsUnread: (state) => {
    if (!state.notifications) return 0
    let x = 0
    for (let group of state.notifications) {
      x += group.notifications.filter((el) => el.viewed === 0).length
    }
    if (x > 1000000) return (x / 1000000).toFixed(2) + "M"
    if (x > 1000) {
      x = (x / 1000).toFixed(2) + "K"
    }
    return x
  }
}
