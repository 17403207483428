export default [
  {
    path: "/home",
    name: "Home",
    component: () => import("@/views/Home.vue"),
    meta: {
      rule: "isGlobal",
      authRequired: true,
      pageTitle: "home",
      icon: "/static/icons/home.svg"
    }
  }
]
