<template>
  <div
    id="empty-cover-art"
    class="
      cursor-pointer
      relative
      text-center
      flex
      justify-center
      items-center
      flex-col
      rounded-lg
      overflow-hidden
    "
  >
    <img
      v-if="viewImage"
      :src="image"
      class="object-cover w-32 h-32 rounded-full"
    />
    <img
      v-if="url && !image"
      :src="url"
      class="object-cover w-32 h-32 rounded-full"
    />
    <input
      type="file"
      name="file"
      accept="img/*"
      class="
        absolute
        top-0
        bottom-0
        right-0
        left-0
        z-10
        h-full
        w-full
        opacity-0
        cursor-pointer
      "
      @change="getImage($event)"
      ref="file"
    />
    <icon icon="image" class="text-4xl" v-if="!viewImage && !url" />
    <div class="py-4" v-if="!viewImage && !url">
      {{ $i18n.t("upload_image") }}
    </div>
  </div>
</template>

<script>
export default {
  name: "t-upload",
  data() {
    return {
      image: null,
      viewImage: false
    }
  },
  props: {
    url: {
      type: String,
      required: false
    },
    file: {
      type: File,
      default: null
    },
    loading: {
      type: Boolean,
      required: true
    }
  },
  methods: {
    getImage(event) {
      let data = event.target.files[0]

      if (data.type.includes("image")) {
        this.$emit("update:file", event.target.files[0])
        let reader = new FileReader()
        reader.readAsDataURL(event.target.files[0])
        reader.onload = (e) => {
          this.image = e.target.result
          this.viewImage = true
          this.upload()
        }
      } else {
        this.$snotify.error(
          this.$i18n.locale === "en"
            ? "Please use a valid format"
            : "Por favor use un formato válido",
          this.$i18n.locale === "en" ? "Invalid format" : "Formato invalido",
          {
            timeout: 5000,
            showProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            titleMaxLength: 40,
            bodyMaxLength: 100,
            position: "rightBottom"
          }
        )
      }
    },
    upload() {
      this.$emit("send")
    },
    triggerClick() {
      this.$refs.file.click()
    }
  }
}
</script>
