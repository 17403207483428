export default {
  setTheme({ commit }, theme) {
    return new Promise(async (resolve) => {
      commit("SET_THEME", theme, { root: true })
      resolve({})
    })
  },
  setSearchModal({ commit }, status) {
    return new Promise(async (resolve) => {
      commit("SET_SEARCH_MODAL", status, { root: true })
      resolve(status)
    })
  },
  setSearchQuery({ commit }, data) {
    return new Promise(async (resolve) => {
      commit("SET_SEARCH_QUERY", data, { root: true })
      resolve(data)
    })
  },
  setUsernameCheck({ commit }, data) {
    return new Promise(async (resolve) => {
      commit("SET_USERNAME_CHECK", data, { root: true })
      resolve(data)
    })
  },
  setNewFriendRequest({ commit }, data) {
    return new Promise(async (resolve, reject) => {
      try {
        commit("NEW_FRIEND_REQUEST", data)
        resolve(data)
      } catch (error) {
        reject(error)
      }
    })
  },
  setFriendRequestAccepted({ commit }, data) {
    return new Promise(async (resolve, reject) => {
      try {
        commit("FRIEND_REQUEST_ACCEPTED", data)
        resolve(data)
      } catch (error) {
        reject(error)
      }
    })
  }
}
