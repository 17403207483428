export default {
  setElement({ commit }, data) {
    return new Promise(async (resolve, reject) => {
      try {
        commit("SET_ITEM", data)
        resolve(data)
      } catch (error) {
        reject(error)
      }
    })
  },
  setUserRandomChat({ commit }, data) {
    return new Promise(async (resolve, reject) => {
      try {
        commit("SET_USER_RANDOM_CHAT", data)
        resolve(data)
      } catch (error) {
        reject(error)
      }
    })
  },
  setElementUser({ commit }, user) {
    return new Promise(async (resolve, reject) => {
      try {
        commit("SET_ELEMENT_USER", user)
        resolve(user)
      } catch (error) {
        reject(error)
      }
    })
  },
  setElementMessages({ commit }, messages) {
    return new Promise(async (resolve, reject) => {
      try {
        commit("SET_ELEMENT_MESSAGES", messages)
        resolve(messages)
      } catch (error) {
        reject(error)
      }
    })
  },
  addChatMessage({ commit }, message) {
    return new Promise(async (resolve, reject) => {
      try {
        commit("ADD_MESSAGE", message)
        resolve(message)
      } catch (error) {
        reject(error)
      }
    })
  },
  clearText({ commit }, clear) {
    return new Promise(async (resolve, reject) => {
      try {
        commit("CLEAR_TEXT", clear)
        resolve(clear)
      } catch (error) {
        reject(error)
      }
    })
  },
  updateElement({ commit }, data) {
    return new Promise(async (resolve, reject) => {
      try {
        commit("UPDATE_FIELD", data)
        resolve(data)
      } catch (error) {
        reject(error)
      }
    })
  },
  friendRequestAccepted({ commit }, data) {
    return new Promise(async (resolve, reject) => {
      try {
        commit("FRIEND_REQUEST_ACCEPTED", data)
        resolve(data)
      } catch (error) {
        reject(error)
      }
    })
  }
}
