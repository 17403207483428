import state from "./moduleUserReportCategoryState"
import actions from "./moduleUserReportCategoryActions"
import getters from "./moduleUserReportCategoryGetters"
import mutations from "./moduleUserReportCategoryMutations"

export default {
  namespaced: true,
  state: state,
  mutations: mutations,
  actions: actions,
  getters: getters
}
