export default {
  user: null,
  googleUser: null,
  isAuthenticated: false,
  OAuthPreferences: null,
  userRole: null,
  notifications: [],
  showNotification: 0,
  newNotification: null,
  isUserLoggedIn: () => {
    return localStorage.getItem("access_token") && this.isAuthenticated
  },
  roleEnum: {
    1: "admin",
    2: "user",
    3: "premium_user"
  }
}
