import state from "./moduleVideoState"
import actions from "./moduleVideoActions"
import getters from "./moduleVideoGetters"
import mutations from "./moduleVideoMutations"

export default {
  namespaced: true,
  state: state,
  mutations: mutations,
  actions: actions,
  getters: getters
}
