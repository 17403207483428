import Vue from "vue"
import { library } from "@fortawesome/fontawesome-svg-core"
import {
  faPlus,
  faImage,
  faCheck,
  faCheckCircle,
  faThLarge,
  faFilePdf,
  faPhone,
  faFileContract,
  faAngleLeft,
  faAngleRight,
  faCaretDown,
  faCaretUp,
  faEllipsisV,
  faPlay,
  faPause,
  faVolumeUp,
  faVolumeDown,
  faVolumeMute,
  faVolumeOff,
  faMusic,
  faCommentDots,
  faTimesCircle,
  faChevronRight,
  faExclamationCircle,
  faEllipsisH,
  faShareAlt,
  faEye,
  faEyeSlash,
  faReply,
  faCommentSlash
} from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome"

library.add(
  faReply,
  faEye,
  faEyeSlash,
  faPlus,
  faImage,
  faCheck,
  faCheckCircle,
  faThLarge,
  faFilePdf,
  faTimesCircle,
  faPhone,
  faFileContract,
  faAngleLeft,
  faAngleRight,
  faCaretDown,
  faCaretUp,
  faEllipsisH,
  faEllipsisV,
  faPlay,
  faPause,
  faVolumeUp,
  faVolumeMute,
  faVolumeOff,
  faVolumeDown,
  faCommentDots,
  faCommentSlash,
  faMusic,
  faChevronRight,
  faExclamationCircle,
  faShareAlt
)

Vue.component("icon", FontAwesomeIcon)
