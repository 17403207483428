export default {
  getElement: (state) => {
    return state.element
  },
  getElementMessages: (state) => {
    return state.element_messages
  },
  getElementTopic: (state) => {
    return state.element_topic
  },

  getClearText: (state) => {
    return state.clear_text
  }
}
