import state from "./moduleStoryState"
import actions from "./moduleStoryActions"
import getters from "./moduleStoryGetters"
import mutations from "./moduleStoryMutations"

export default {
  namespaced: true,
  state: state,
  mutations: mutations,
  actions: actions,
  getters: getters
}
