import state from "./modulePictureState"
import actions from "./modulePictureActions"
import getters from "./modulePictureGetters"
import mutations from "./modulePictureMutations"

export default {
  namespaced: true,
  state: state,
  mutations: mutations,
  actions: actions,
  getters: getters
}
