export default {
  SET_THEME(state, item) {
    state.theme = item
  },
  SET_SEARCH_MODAL(state, status) {
    state.searchModal = status
  },
  SET_SEARCH_QUERY(state, data) {
    state.searchQuery = data
  },
  SET_USERNAME_CHECK(state, data) {
    state.usernameCheck = data
  },
  NEW_FRIEND_REQUEST(state, data) {
    state.new_friend_request = data.status
    state.friend_request_sender = data.user_sent
  },
  FRIEND_REQUEST_ACCEPTED(state) {
    state.new_friend_request = false
    state.friend_request_sender = null
  }
}
