export default {
  handleUserConnection({ commit }, user) {
    return new Promise(async (resolve, reject) => {
      try {
        commit("USER_CONNECTION", user)
        resolve(user)
      } catch (error) {
        reject(error)
      }
    })
  },
  setElement({ commit }, room) {
    return new Promise(async (resolve, reject) => {
      try {
        commit("SET_ITEM", room)
        resolve(room)
      } catch (error) {
        reject(error)
      }
    })
  },
  updateElement({ commit }, data) {
    return new Promise(async (resolve, reject) => {
      try {
        commit("UPDATE_ITEM", data)
        resolve(room)
      } catch (error) {
        reject(error)
      }
    })
  },
  setElementMessages({ commit }, messages) {
    return new Promise(async (resolve, reject) => {
      try {
        commit("SET_ELEMENT_MESSAGES", messages)
        resolve(messages)
      } catch (error) {
        reject(error)
      }
    })
  },
  addItem({ commit }, room) {
    return new Promise(async (resolve, reject) => {
      try {
        commit("ADD_ITEM", room)
        resolve(room)
      } catch (error) {
        reject(error)
      }
    })
  },
  addChatMessage({ commit }, message) {
    return new Promise(async (resolve, reject) => {
      try {
        commit("ADD_MESSAGE", message)
        resolve(message)
      } catch (error) {
        reject(error)
      }
    })
  },
  chatTyping({ commit }, data) {
    return new Promise(async (resolve, reject) => {
      try {
        commit("CHAT_TYPING", data)
        resolve(data)
      } catch (error) {
        reject(error)
      }
    })
  },
  clearText({ commit }, clear) {
    return new Promise(async (resolve, reject) => {
      try {
        commit("CLEAR_TEXT", clear)
        resolve(clear)
      } catch (error) {
        reject(error)
      }
    })
  },
  readMsgs({ commit }, data) {
    return new Promise(async (resolve, reject) => {
      try {
        commit("MARK_AS_READ", data)
        resolve(data)
      } catch (error) {
        reject(error)
      }
    })
  }
}
