import state from "./moduleVerificationState"
import actions from "./moduleVerificationActions"
import getters from "./moduleVerificationGetters"
import mutations from "./moduleVerificationMutations"

export default {
  namespaced: true,
  state: state,
  mutations: mutations,
  actions: actions,
  getters: getters
}
