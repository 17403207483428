export default {
  getElement: (state) => {
    return state.element
  },
  getElementUser: (state) => {
    return state.element_user
  },
  getAllUnpaginated: (state) => {
    return state.unpaginated_elements
  },
  getElementMessages: (state) => {
    return state.element_messages
  },
  getClearText: (state) => {
    return state.clear_text
  }
}
