export default {
  ADD_ITEM(state, item) {
    state.elements.push(item)
    state.unpaginated_elements.push(item)
  },
  SET_ITEMS(state, items) {
    state.elements = items
  },

  SET_ITEMS_NO_PAGINATE(state, items) {
    state.unpaginated_elements = items
  },
  SET_CONTENT_CREATORS(state, items) {
    state.content_creator = items
  },

  SET_GROUPED_ELEMENTS(state, items) {
    state.grouped_elements = items
  },

  SET_GROUPED_ELEMENTS2(state, items) {
    state.grouped_elements2 = items
  },

  SET_GROUPED_ELEMENTS3(state, items) {
    state.grouped_elements3 = items
  },

  UPDATE_ITEM(state, data) {
    let unpaginated_index = state.unpaginated_elements.findIndex(
      (x) => x.id === data.id
    )
    let regular_index = state.elements.findIndex((x) => x.id === data.id)
    if (unpaginated_index !== -1)
      state.unpaginated_elements[unpaginated_index] = data

    if (regular_index !== -1) state.elements[regular_index] = data
  },
  DELETE_COMMENT(state, data) {
    let unpaginated_index = state.unpaginated_elements.findIndex(
      (x) => x.id === data.id_post
    )
    let regular_index = state.elements.findIndex((x) => x.id === data.id_post)
    if (unpaginated_index !== -1)
      state.unpaginated_elements[unpaginated_index].total_comments--

    if (regular_index !== -1) state.elements[regular_index].total_comments--
  },
  SET_COMMENTS(state, data) {
    state.comments = data
  },

  SET_LOAD_NEW_POSTS(state, data) {
    state.load_new_posts = data
  },
  SET_SUGGESTED_VIDEOS(state, data) {
    state.suggested_videos = data
  },

  SET_RESUMABLE_VIDEO(state, data) {
    state.resumable_video = data
  },

  CHANGE_PROGRESS_RESUMABLE_VIDEO(state, data) {
    state.resumable_video.progress = data
  }
}
