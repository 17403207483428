import state from "./moduleCountryState"
import actions from "./moduleCountryActions"
import getters from "./moduleCountryGetters"
import mutations from "./moduleCountryMutations"

export default {
  namespaced: true,
  state: state,
  mutations: mutations,
  actions: actions,
  getters: getters
}
