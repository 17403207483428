/* eslint-disable no-unused-vars */
import Vue from "vue"
import App from "./App.vue"
import router from "./router"
import store from "./store/store"
import "./vue-tailwind.js"
import socket from "./socket/socket"

/* Styles */
import "./assets/scss/main.scss"

/* Tailwind */
import "./assets/css/tailwind.css"

/* ACL Permission */
import acl from "@/acl/acl"

/* Filters */
import "./filters/filters"

/* import the fontawesome core */
import { library } from "@fortawesome/fontawesome-svg-core"

/* import font awesome icon component */
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome"

/* import specific icons */
import { fas } from "@fortawesome/free-solid-svg-icons"

/* add icons to the library */
library.add(fas)

/* add font awesome icon component */
Vue.component("font-awesome-icon", FontAwesomeIcon)

/* Adsense */

import Ads from "vue-google-adsense"

Vue.use(require("vue-script2"))
Vue.use(Ads.Adsense)
Vue.use(Ads.InFeedAdsense)

Vue.prototype.$http = axios

import VueObserveVisibility from "vue-observe-visibility"

Vue.use(VueObserveVisibility)

import Snotify from "vue-snotify"
Vue.use(Snotify, {
  global: {
    maxOnScreen: 3
  }
})

import VeeValidate, { Validator } from "vee-validate"
import {
  es,
  en,
  alpha_spaces_rule,
  simple_required,
  simple_max,
  youtube_url,
  phone_rule,
  alpha_symbol_rule,
  username_rule
} from "./utils/dictionary"

const VueValidationEs = require("vee-validate/dist/locale/es")
const VueValidationEn = require("vee-validate/dist/locale/en")
const {
  detectHashtagsAndUsernames,
  detectUsernameInput,
  removeAtSymbol
} = require("./utils/textProcessing")
const { getExtension } = require("./utils/file")

let browser_lang =
  navigator.language && ["es", "en"].includes(navigator.language.split("-")[0])
    ? navigator.language.split("-")[0]
    : "es"

if (store.state.auth.user) browser_lang = store.state.auth.user.lang

Vue.use(VeeValidate, {
  locale: browser_lang,
  dictionary: {
    es: VueValidationEs,
    en: VueValidationEn
  }
})

import axios from "./utils/axios"
import VueI18n from "vue-i18n"

import es_actions from "./i18n/es/actions.json"
import es_pages from "./i18n/es/pages.json"
import es_misc from "./i18n/es/misc.json"
import en_actions from "./i18n/en/actions.json"
import en_pages from "./i18n/en/pages.json"
import en_misc from "./i18n/en/misc.json"

const messages = {
  en: {
    ...en_actions,
    ...en_pages,
    ...en_misc
  },
  es: {
    ...es_actions,
    ...es_pages,
    ...es_misc
  }
}
Vue.use(VueI18n)

const i18n = new VueI18n({
  locale: browser_lang,
  fallbackLocale: "en",
  messages
})

Validator.localize(browser_lang == "es" ? es : en)
Validator.extend("alpha_spaces", alpha_spaces_rule)
Validator.extend("phone", phone_rule)
Validator.extend("alpha_symbol", alpha_symbol_rule)
Validator.extend("username", username_rule)
Validator.extend("youtube_url", youtube_url)
Validator.extend("simple_required", simple_required, { computesRequired: true })
Validator.extend("simple_max", simple_max, {
  paramNames: ["maxLength"]
})

require("intersection-observer")

/* Vue schedule */

require("./plugins/fontawesome")
require("./globalComponents")

Vue.config.productionTip = false

Vue.prototype.$socket = socket
Vue.prototype.$getExtension = getExtension
Vue.prototype.$detectHashtagsAndUsernames = detectHashtagsAndUsernames
Vue.prototype.$detectUsernameInput = detectUsernameInput
Vue.prototype.$removeAtSymbol = removeAtSymbol

new Vue({
  router,
  store,
  i18n,
  sockets: {
    connect: function () {
      console.log("Socket connected")
    },
    disconnect: function () {
      console.log("Socket disconnected")
    }
  },
  beforeCreate() {
    const user = store.state.auth.user
    if (user) {
      Vue.prototype.$socket.emit("user_connected", {
        id_user: user.id
      })
      Vue.prototype.$socket.emit("join_topics", {
        id_user: user.id
      })
    }
  },
  render: (h) => h(App)
}).$mount("#app")
