import state from "./moduleRadioState"
import actions from "./moduleRadioActions"
import getters from "./moduleRadioGetters"
import mutations from "./moduleRadioMutations"

export default {
  namespaced: true,
  state: state,
  mutations: mutations,
  actions: actions,
  getters: getters
}
