import getFormData from "@/utils/convertToFormData"
import axios from "@/utils/axios.js"
import axiosV2 from "@/utils/axiosV2.js"

export default {
  create({ commit }, { payload, route, module }) {
    return new Promise(async (resolve, reject) => {
      await axios
        .post(route, getFormData(payload), {
          headers: {
            Authorization:
              "Bearer " + window.localStorage.getItem("access_token")
          }
        })
        .then(({ data: response }) => {
          if (response.data) {
            if (module)
              commit(`${module}/ADD_ITEM`, response.data, { root: true })
          }
          resolve(response)
        })
        .catch((err) => {
          reject(err)
        })
    })
  },

  getItems(
    { commit },
    { route, module, noPaginate = false, isMaster = false, params = {} }
  ) {
    let url = `${module}/SET_ITEMS`
    if (noPaginate) url += "_NO_PAGINATE"

    return new Promise(async (resolve, reject) => {
      await axios
        .get(isMaster ? "/master" + route : route, {
          params,
          headers: {
            Authorization:
              "Bearer " + window.localStorage.getItem("access_token")
          }
        })
        .then(({ data: response }) => {
          if (module) {
            commit(
              url,
              response.data.data ? response.data.data : response.data,
              {
                root: true
              }
            )
          }

          resolve(response)
        })
        .catch((err) => {
          reject(err)
        })
    })
  },

  getItemsV2(
    { commit },
    { route, module, noPaginate = false, isMaster = false, params = {} }
  ) {
    let url = `${module}/SET_ITEMS`
    if (noPaginate) url += "_NO_PAGINATE"

    return new Promise(async (resolve, reject) => {
      await axiosV2
        .get(isMaster ? "/master" + route : route, {
          params,
          headers: {
            Authorization:
              "Bearer " + window.localStorage.getItem("access_token")
          }
        })
        .then(({ data: response }) => {
          if (module) {
            commit(
              url,
              response.data.data ? response.data.data : response.data,
              {
                root: true
              }
            )
          }

          resolve(response)
        })
        .catch((err) => {
          reject(err)
        })
    })
  },

  getItem({ commit }, { id, route, module }) {
    return new Promise(async (resolve, reject) => {
      await axios
        .get(`${route}/${id}`, {
          headers: {
            Authorization:
              "Bearer " + window.localStorage.getItem("access_token")
          }
        })
        .then(({ data: response }) => {
          if (module)
            commit(`${module}/SET_ITEM`, response.data, { root: true })
          resolve(response)
        })
        .catch((err) => {
          reject(err)
        })
    })
  },

  get({ commit }, { route, module }) {
    return new Promise(async (resolve, reject) => {
      await axios
        .get(route, {
          /*  headers: {
             Authorization:
               "Bearer " + window.localStorage.getItem("access_token")
           } */
        })
        .then(({ data: response }) => {
          if (module)
            commit(`${module}/SET_ITEM`, response.data, { root: true })
          resolve(response)
        })
        .catch((err) => {
          reject(err)
        })
    })
  },

  update({ commit }, { payload, route, module, isCurrentUser }) {
    return new Promise(async (resolve, reject) => {
      await axios
        .put(`${route}/${payload.id}`, getFormData(payload), {
          headers: {
            Authorization:
              "Bearer " + window.localStorage.getItem("access_token")
          }
        })
        .then(({ data: response }) => {
          try {
            if (response.code === 200) {
              if (isCurrentUser) {
                commit("auth/SET_USER", response.data, { root: true })
              } else {
                if (module) {
                  commit(`${module}/UPDATE_ITEM`, response.data, { root: true })
                  commit(`${module}/SET_ITEM`, response.data, { root: true })
                }
              }
            }
          } catch (error) {
            console.log(error)
          }

          resolve(response)
        })
        .catch((err) => {
          reject(err)
        })
    })
  },

  changeStatus({ commit }, { payload, route, module }) {
    return new Promise(async (resolve, reject) => {
      await axios
        .put(
          `${route}/change_status/${payload.id}/${payload.status}`,
          {},
          {
            headers: {
              Authorization:
                "Bearer " + window.localStorage.getItem("access_token")
            }
          }
        )
        .then(({ data: response }) => {
          try {
            if (module) {
              commit(`${module}/UPDATE_ITEM`, response.data, { root: true })
              commit(`${module}/SET_ITEM`, response.data, { root: true })
            }
          } catch (error) {
            console.log(error)
          }
          resolve(response)
        })
        .catch((err) => {
          reject(err)
        })
    })
  },
  put({ commit }, { route, payload, headers = {}, useAuthorization }) {
    const headersx = headers
    if (useAuthorization)
      headersx.Authorization = `Bearer ${window.localStorage.getItem(
        "access_token"
      )}`

    return new Promise(async (resolve, reject) => {
      await axios
        .put(route, payload, { headers: headersx })
        .then((response) => {
          resolve(response)
        })
        .catch((err) => {
          reject(err)
        })
    })
  }
}
