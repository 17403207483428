export default {
  getAll: (state) => {
    return state.elements
  },
  getAllUnpaginated: (state) => {
    return state.unpaginated_elements
  },
  getAllGrouped: (state) => {
    return state.grouped_elements
  },
  getContentCreators: (state) => {
    return state.content_creator
  },
  getAllGrouped2: (state) => {
    return state.grouped_elements2
  },
  getAllGrouped3: (state) => {
    return state.grouped_elements3
  },
  getComments: (state) => {
    return state.comments
  },
  getLoadNewPosts: (state) => {
    return state.load_new_posts
  },
  getAllSuggestedVideos: (state) => {
    return state.suggested_videos
  },
  getResumableVideo: (state) => {
    return state.resumable_video
  }
}
