export default {
  SET_ITEMS(state, items) {
    state.elements = items
  },

  SET_ITEMS_NO_PAGINATE(state, items) {
    state.unpaginated_elements = items
  },

  ADD_ITEM(state, item) {
    state.unpaginated_elements.push(item)
    state.elements.push(item)
    state.unpaginated_elements = state.unpaginated_elements.sort(
      (a, b) =>
        new Date(b.last_message.updated_at) -
        new Date(a.last_message.updated_at)
    )
    state.elements = state.elements.sort(
      (a, b) =>
        new Date(b.last_message.updated_at) -
        new Date(a.last_message.updated_at)
    )
  },

  SET_ITEM(state, item) {
    state.element = item
  },

  SET_ITEM_USER(state, user) {
    state.element_user = user
  },

  SET_ELEMENT_MESSAGES(state, messages) {
    state.element_messages = messages
  },

  ADD_MESSAGE(state, message) {
    let aux_index = -1
    if (!message.chat) {
      if (state.element && state.element.id === message.id_chat) {
        state.element_messages.push(message)
      } else {
        aux_index = state.unpaginated_elements.findIndex(
          (x) => x.id === message.id_chat
        )
        state.unpaginated_elements[aux_index].unread_messages++
      }
    } else {
      if (state.element && state.element.id === message.chat.id) {
        // If the new message belongs to the currently selected chat
        state.element_messages.push(message)
      } else {
        aux_index = state.unpaginated_elements.findIndex(
          (x) => x.id === message.id_chat
        )
        state.unpaginated_elements[aux_index].unread_messages++
      }
    }

    if (message.chat) {
      let unpaginated_index = state.unpaginated_elements.findIndex(
        (x) => x.id === message.chat.id
      )
      let paginated_index = state.elements.findIndex(
        (x) => x.id === message.chat.id
      )
      let selected_chat
      if (unpaginated_index !== -1) {
        selected_chat = state.unpaginated_elements[unpaginated_index]
        selected_chat.last_message = message
        Object.assign(
          state.unpaginated_elements[unpaginated_index],
          selected_chat
        )
        state.unpaginated_elements = state.unpaginated_elements.sort(
          (a, b) =>
            new Date(b.last_message.updated_at) -
            new Date(a.last_message.updated_at)
        )
      }
      if (paginated_index !== -1) {
        selected_chat = state.elements[paginated_index]
        selected_chat.last_message = message
        Object.assign(state.elements[paginated_index], selected_chat)
        state.elements = state.elements.sort(
          (a, b) =>
            new Date(b.last_message.updated_at) -
            new Date(a.last_message.updated_at)
        )
      }
    }
  },

  CLEAR_TEXT(state, clear) {
    state.clear_text = clear
  },

  CHAT_TYPING(state, { user, chat, isTyping }) {
    let unpaginated_index = state.unpaginated_elements.findIndex(
      (x) => x.id === chat.id
    )

    let paginated_index = state.elements.findIndex((x) => x.id === chat.id)
    let correct_user
    let selected_chat
    // Change is_typing status to state.unpaginated_elements
    if (unpaginated_index !== -1) {
      correct_user =
        state.unpaginated_elements[unpaginated_index].id_user_a === user.id
          ? "user_a"
          : "user_b"
      selected_chat = state.unpaginated_elements[unpaginated_index]
      selected_chat[correct_user].is_typing = isTyping
      selected_chat[correct_user].updated_at = new Date()

      Object.assign(
        state.unpaginated_elements[unpaginated_index],
        selected_chat
      )
    }
    // Change is_typing status to state.elements
    if (paginated_index !== -1) {
      correct_user =
        state.elements[paginated_index].id_user_a === user.id
          ? "user_a"
          : "user_b"
      selected_chat = state.elements[paginated_index]
      selected_chat[correct_user].is_typing = isTyping
      selected_chat[correct_user].updated_at = new Date()
      Object.assign(state.elements[paginated_index], selected_chat)
    }
  },

  USER_CONNECTION(state, user) {
    let unpaginated_index = state.unpaginated_elements.findIndex(
      (x) => x.id_user_a === user.id || x.id_user_b === user.id
    )
    let paginated_index = state.elements.findIndex(
      (x) => x.id_user_a === user.id || x.id_user_b === user.id
    )
    let selected_chat = {}
    let correct_user
    if (unpaginated_index !== -1) {
      correct_user =
        state.unpaginated_elements[unpaginated_index].id_user_a === user.id
          ? "user_a"
          : "user_b"
      selected_chat = { ...state.unpaginated_elements[unpaginated_index] }
      selected_chat[correct_user].connected = user.connected
      selected_chat[correct_user].last_connection = user.last_connection
      Object.assign(
        state.unpaginated_elements[unpaginated_index],
        selected_chat
      )
    }
    if (paginated_index !== -1) {
      correct_user =
        state.elements[paginated_index].id_user_a === user.id
          ? "user_a"
          : "user_b"
      selected_chat = { ...state.elements[paginated_index] }
      selected_chat[correct_user].connected = user.connected
      selected_chat[correct_user].last_connection = user.last_connection
      Object.assign(state.elements[paginated_index], selected_chat)
    }
  },

  MARK_AS_READ(state, { chat, id_user }) {
    let unpaginated_index = state.unpaginated_elements.findIndex(
      (x) => x.id === chat.id
    )
    let paginated_index = state.elements.findIndex((x) => x.id === chat.id)
    let selected_chat = {}
    if (unpaginated_index !== -1) {
      selected_chat = state.unpaginated_elements[unpaginated_index]
      if (selected_chat.last_message.id_user !== id_user) {
        selected_chat.last_message.unread = 0
        selected_chat.unread_messages = 0
        Object.assign(
          state.unpaginated_elements[unpaginated_index],
          selected_chat
        )
      }
    }
    if (paginated_index !== -1) {
      selected_chat = state.elements[paginated_index]
      if (selected_chat.last_message.id_user !== id_user) {
        selected_chat.last_message.unread = 0
        selected_chat.last_message.unread_messages = 0
        Object.assign(state.elements[paginated_index], selected_chat)
      }
    }
  }
}
