export default {
  setElement({ commit }, chat) {
    return new Promise(async (resolve, reject) => {
      try {
        commit("SET_ITEM", chat)
        resolve(chat)
      } catch (error) {
        reject(error)
      }
    })
  },
  setElementMessages({ commit }, messages) {
    return new Promise(async (resolve, reject) => {
      try {
        commit("SET_ELEMENT_MESSAGES", messages)
        resolve(messages)
      } catch (error) {
        reject(error)
      }
    })
  },
  removeExpiredMessage({ commit }, messageId) {
    return new Promise(async (resolve, reject) => {
      try {
        commit("REMOVE_EXPIRED_MESSAGE", messageId)
        resolve(messageId)
      } catch (error) {
        reject(error)
      }
    })
  },
  setUserPublicRoom({ commit }, data) {
    return new Promise(async (resolve, reject) => {
      try {
        commit("SET_USER_PUBLIC_ROOM", data)
        resolve(data)
      } catch (error) {
        reject(error)
      }
    })
  },
  addItem({ commit }, chat) {
    return new Promise(async (resolve, reject) => {
      try {
        commit("ADD_ITEM", chat)
        resolve(chat)
      } catch (error) {
        reject(error)
      }
    })
  },
  addChatMessage({ commit }, message) {
    return new Promise(async (resolve, reject) => {
      try {
        commit("ADD_MESSAGE", message)
        resolve(message)
      } catch (error) {
        reject(error)
      }
    })
  },
  clearText({ commit }, clear) {
    return new Promise(async (resolve, reject) => {
      try {
        commit("CLEAR_TEXT", clear)
        resolve(clear)
      } catch (error) {
        reject(error)
      }
    })
  },
  setForbiddenWordsCount({ commit }, data) {
    return new Promise(async (resolve, reject) => {
      try {
        commit("SET_FORBIDDEN_WORDS_COUNT", data)
        resolve(data)
      } catch (error) {
        reject(error)
      }
    })
  },
  toggleUserMute({ commit }, data) {
    return new Promise(async (resolve, reject) => {
      try {
        commit("TOGGLE_USER_MUTE", data)
        resolve(data)
      } catch (error) {
        reject(error)
      }
    })
  },
  update({ commit }, data) {
    return new Promise(async (resolve, reject) => {
      try {
        commit("UPDATE_FIELD", data)
        resolve(data)
      } catch (error) {
        reject(error)
      }
    })
  },
  friendRequestAccepted({ commit }, data) {
    return new Promise(async (resolve, reject) => {
      try {
        commit("FRIEND_REQUEST_ACCEPTED", data)
        resolve(data)
      } catch (error) {
        reject(error)
      }
    })
  },
  updateChatRequestStatus({ commit }, { id_user_sent, id_user_received, accepted }) {
    return new Promise(async (resolve, reject) => {
      try {
        commit("UPDATE_CHAT_REQUEST", { id_user_sent, id_user_received, accepted })
        resolve({ id_user_sent, id_user_received, accepted })
      } catch (error) {
        reject(error)
      }
    })
  }

  /*   newBanVotation({ commit }, { user_ban_votation, status }) {
    return new Promise(async (resolve, reject) => {
      try {
        commit("SET_USER_BAN_VOTATION", user_ban_votation)
        commit("SET_NEW_USER_BAN_VOTATION", status)
        resolve({ user_ban_votation, status })
      } catch (error) {
        reject(error)
      }
    })
  },
  setBannedUser({ commit }, data) {
    return new Promise(async (resolve, reject) => {
      try {
        commit("SET_BANNED_USER", data)
        resolve(data)
      } catch (error) {
        reject(error)
      }
    })
  }, */
}
