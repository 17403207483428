import state from "./moduleCutsState"
import actions from "./moduleCutsActions"
import getters from "./moduleCutsGetters"
import mutations from "./moduleCutsMutations"

export default {
  namespaced: true,
  state: state,
  mutations: mutations,
  actions: actions,
  getters: getters
}
