export default {
  getAllUnpaginated: (state) => {
    return state.unpaginated_elements
  },
  getAllCuts: (state) => {
    return state.jevi_cuts
  },
  getItem: (state) => {
    return state.element
  },
  getItemUser: (state) => {
    return state.element_user
  },
  getLoadNew: (state) => {
    return state.load_new
  },
  getResumableVideo: (state) => {
    return state.resumable_video
  }
}
