import state from "./moduleRandomChatState"
import actions from "./moduleRandomChatActions"
import getters from "./moduleRandomChatGetters"
import mutations from "./moduleRandomChatMutations"

export default {
  namespaced: true,
  state: state,
  mutations: mutations,
  actions: actions,
  getters: getters
}
