import axios from "axios"
var url_domain = window.location.host

// Default URL
var config_url = "https://apitest.jeviapp.com/api/2.0"

if (url_domain == "app.jeviapp.com") config_url = "https://api.jeviapp.com/api/2.0"

console.log("Backend Conected V2: " + config_url)

export default axios.create({
  baseURL: config_url
})
