<template>
  <div>
    <t-dropdown variant="tooltipTop">
      <div
        slot="trigger"
        slot-scope="{
          mousedownHandler,
          focusHandler,
          blurHandler,
          keydownHandler
        }"
        class="flex"
      >
        <button
          class="flex items-center justify-center flex-shrink-0 px-3 py-2 text-black dark:text-white bg-transparent rounded-r"
          @mousedown="mousedownHandler"
          @focus="focusHandler"
          @blur="blurHandler"
          @keydown="keydownHandler"
        >
          <img
            src="/static/icons/attachment.svg"
            class="mr-2 my-auto"
            alt="Attach icon"
            style="
              cursor: pointer;
              width: 25px;
              filter: invert(78%) sepia(50%) saturate(4740%) hue-rotate(359deg)
                brightness(102%) contrast(108%);
            "
          />
        </button>
      </div>

      <div slot-scope="{ blurHandler }">
        <button
          v-if="allowImages"
          class="block w-full px-1 py-1 text-sm leading-5 text-black dark:text-white transition duration-150 ease-in-out bg-gray-100 dark:bg-gray-700 dark:hover:bg-gray-800 hover:bg-gray-200 focus:outline-none focus:bg-gray-200 dark:focus:bg-gray-800"
          role="menuitem"
          @blur="blurHandler"
          @click="$refs['image-input'].click()"
        >
          <icon icon="image" class="text-green-600 mr-1" />
          {{ $i18n.t("picture") }}
        </button>
        <button
          v-if="allowFiles"
          class="block w-full px-1 py-1 text-sm leading-5 text-black dark:text-white transition duration-150 ease-in-out bg-gray-100 dark:bg-gray-700 dark:hover:bg-gray-800 hover:bg-gray-200 focus:outline-none focus:bg-gray-200 dark:focus:bg-gray-800"
          role="menuitem"
          @blur="blurHandler"
          @click="$refs['file-input'].click()"
        >
          <icon icon="file-pdf" class="text-blue-600 mr-1" />
          {{ $i18n.t("file") }}
        </button>
        <button
          v-if="allowAudio"
          class="block w-full px-1 py-1 text-sm leading-5 text-black dark:text-white transition duration-150 ease-in-out bg-gray-100 dark:bg-gray-700 dark:hover:bg-gray-800 hover:bg-gray-200 focus:outline-none focus:bg-gray-200 dark:focus:bg-gray-800"
          role="menuitem"
          @blur="blurHandler"
          @click="$refs['audio-input'].click()"
        >
          <icon icon="music" class="text-red-600 mr-1" />
          {{ $i18n.t("audio") }}
        </button>
      </div>
    </t-dropdown>
    <input
      type="file"
      accept="audio/*"
      class="hidden"
      @change="updateFile($event)"
      ref="audio-input"
    />
    <input
      type="file"
      class="hidden"
      @change="updateFile($event)"
      ref="file-input"
    />
    <input
      type="file"
      accept="image/*"
      class="hidden"
      @change="updateFile($event)"
      ref="image-input"
    />
    <t-modal
      :header="$i18n.t('send_file')"
      :value="showModal"
      @closed="onClosed"
      variant="lg"
    >
      <template v-if="file && file.type.includes('image')">
        <img :src="image" alt="Chat image upload" class="mx-auto my-auto" />
      </template>
      <template v-else>
        <div class="flex flex-col items-center justify-center">
          <icon icon="file-contract" class="text-primary-800 text-3xl" />
          <div class="py-2 text-sm text-black dark:text-white">{{ name }}</div>
        </div>
      </template>
      <div class="py-2 text-center text-sm text-black dark:text-white">
        {{ $i18n.t("file_upload_confirmation") }}
      </div>

      <template v-slot:footer>
        <div class="flex justify-end">
          <t-button
            variant="outline-gray-sm"
            type="button"
            @click="showModal = false"
            class="mx-1"
            >{{ $i18n.t("cancel") }}</t-button
          >

          <t-button type="button" @click="upload" class="mx-1">{{
            $i18n.t("send")
          }}</t-button>
        </div>
      </template>
    </t-modal>
  </div>
</template>

<script>
export default {
  name: "chat-upload",
  data() {
    return {
      image: null,
      file: null,
      name: "",
      showModal: false
    }
  },
  props: {
    allowImages: {
      type: Boolean,
      default: true
    },
    allowFiles: {
      type: Boolean,
      default: true
    },
    allowAudio: {
      type: Boolean,
      default: true
    }
  },
  methods: {
    updateFile(event) {
      let data = event.target.files[0]
      this.file = data
      if (event.target.files[0]) {
        if (data.type.includes("image")) {
          let reader = new FileReader()
          reader.readAsDataURL(event.target.files[0])
          reader.onload = (e) => {
            this.image = e.target.result
          }
        }
        this.name = data.name
        this.showModal = true
      }
    },
    onClosed() {
      this.showModal = false
      this.file = null
    },
    upload() {
      this.$emit("upload", this.file)
    }
  }
}
</script>
