<template>
  <div class="relative">
    <img
      @click="showModal = true"
      class="w-full object-cover z-20"
      style="max-height: 40vh; cursor: pointer"
      :src="
        user.header_picture
          ? user.header_picture
          : '/static/banner-placeholder.jpg'
      "      alt="Profile header"
    />
    <icon
      @click="showModal = true"
      style="width: 26px; height: 26px; cursor: pointer"
      icon="plus"
      class="rounded-full bg-primary-700 text-white text-3xl absolute top-1 right-1 p-2"
    />

    <template>
      <t-modal
        :header="$i18n.t('change_header_img')"
        :value="showModal"
        @closed="onClosed"
        variant="xl"
        @before-close="beforeClose"
        :clickToClose="false"
      >
        <t-upload-file
          v-show="!base64Img"
          class="sm:w-2/5 mx-auto"
          :file.sync="header_picture"
          :base64.sync="base64Img"
          :loading.sync="isLoading"
          ref="headerImg"
        />

        <cropper
          class="cropper"
          :src="base64Img"
          :stencil-props="{
            aspectRatio: 16 / 9
          }"
          @change="change"
        />

        <template v-slot:footer>
          <div class="flex justify-end">
            <t-button
              :disabled="!base64Img"
              variant="outline-gray-sm"
              type="button"
              @click="resetPicture"
              class="mx-1"
              >{{ $i18n.t("reset") }}</t-button
            >
            <t-button
              variant="outline-gray-sm"
              type="button"
              @click="showModal = false"
              class="mx-1"
              >{{ $i18n.t("cancel") }}</t-button
            >

            <t-button
              type="button"
              :disabled="isLoading"
              @click="updatePicture"
              class="mx-1"
              >{{ $i18n.t("save") }}
              <span v-if="isLoading">
                <t-loader extraClass="ml-1 border-orange-primary my-auto"
              /></span>
            </t-button>
          </div>
        </template>
      </t-modal>
    </template>
  </div>
</template>

<script>
const notify = () => import("@/utils/notify.js")
import { Cropper } from "vue-advanced-cropper"
import "vue-advanced-cropper/dist/style.css"

export default {
  name: "header-upload",
  data() {
    return {
      image: null,
      viewImage: false,
      base64Img: null,
      showModal: false,
      header_picture: null,
      isLoading: false
    }
  },
  components: {
    Cropper
  },
  props: {
    user: {
      type: Object,
      default: () => {}
    }
  },
  methods: {
    resetPicture() {
      this.header_picture = null
      this.base64Img = null
      this.$refs.headerImg.image = null
      this.$refs.headerImg.$refs.file = null
    },
    beforeClose() {
      if (this.$refs.headerImg) {
        this.$refs.headerImg.image = null
        this.$refs.headerImg.$refs.file = null
      }

      this.header_picture = null
      this.base64Img = null
    },
    onClosed() {
      this.showModal = false
    },
    change({ canvas }) {
      canvas.toBlob((blob) => {
        const file = new File([blob], "image.png")
        this.header_picture = file
      })
    },
    updatePicture() {
      this.isLoading = true
      this.$store
        .dispatch("global/update", {
          payload: {
            id: this.user.id,
            header_picture: this.header_picture
          },
          route: "/user/update",
          module: "user",
          isCurrentUser: true
        })
        .then((response) => {
          if ([200, 201].includes(response.code)) {
            this.$emit("userUpdate", response.data)
            this.showModal = false
          }
          notify().then(({ notification }) => {
            notification(
              "master-update",
              response.code,
              this.$snotify,
              this.$i18n.locale
            )
          })
          this.isLoading = false
        })
        .catch((error) => {
          this.isLoading = false
          notify().then(({ notification }) => {
            notification("error", error.code, this.$snotify, this.$i18n.locale)
          })
        })
    }
  }
}
</script>

<style lang="scss">
.cropper {
  max-height: 70vh;
}
</style>
