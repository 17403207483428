<template>
  <div
    id="empty-cover-art"
    class="cursor-pointer relative w-full h-full text-center flex justify-center items-center flex-col rounded-lg overflow-hidden bg-white dark:bg-gray-600 shadow-2xl p-3"
  >
    <input
      v-if="!readFile || type === 'image'"
      type="file"
      name="file"
      accept="*"
      class="absolute top-0 bottom-0 right-0 left-0 z-10 h-full w-full opacity-0 cursor-pointer"
      @change="getImage($event)"
      ref="file"
    />

    <div v-if="readFile">
      <img
        v-if="type === 'image'"
        :src="readFile"
        class="w-full object-cover"
        style="aspect-ratio: 1"
      />
      <template v-if="type === 'video'">
        <video
          id="file-upload-video"
          class="mx-auto px-2 py-1"
          :src="readFile"
          playsinline
          autoplay
          controls
          loop
          controlsList="nodownload"
        ></video>

        <t-button type="button" @click="resetFile" class="mx-auto">{{
          $i18n.t("reset")
        }}</t-button>
      </template>
    </div>

    <div v-else class="py-4">
      <img
        src="/static/icons/picture_icon.svg"
        alt="Add image icon"
        class="mx-auto dark:filter invert w-3/5 mx-auto"
      />
      <p
        class="text-md md:text-lg dark:text-white text-center"
        v-if="type === 'image'"
      >
        {{ this.$i18n.t("select_an_image") }}
      </p>
      <p
        class="text-md md:text-lg dark:text-white text-center"
        v-if="type === 'video'"
      >
        {{ this.$i18n.t("select_a_video") }}
      </p>
    </div>
  </div>
</template>

<script>
export default {
  name: "t-upload-file",
  data() {
    return {
      readFile: null,
      name: "",
      url: null
    }
  },
  props: {
    file: {
      type: File,
      default: null
    },
    base64: {
      type: String,
      default: ""
    },
    aspectRatio: {
      type: Number,
      default: 0
    },
    loading: {
      type: Boolean,
      required: true
    },
    type: {
      type: String,
      default: "image"
    }
  },
  methods: {
    resetFile() {
      this.readFile = null
      this.$emit("update:file", null)
      this.$emit("update:aspectRatio", 0)
    },
    getImage(event) {
      let data = event.target.files[0]

      if (data.type.includes("image")) {
        this.$emit("update:file", event.target.files[0])
        let reader = new FileReader()
        reader.readAsDataURL(event.target.files[0])
        reader.onload = (e) => {
          this.readFile = e.target.result
          this.$emit("update:base64", e.target.result)
        }
      } else if (this.type === "video" && data.type.includes("video")) {
        this.$emit("update:file", event.target.files[0])

        let reader = new FileReader()
        reader.readAsDataURL(event.target.files[0])
        reader.onload = (e) => {
          this.readFile = e.target.result
        }
      } else {
        this.$snotify.error(
          this.$i18n.locale === "en"
            ? "Please use a valid format"
            : "Por favor use un formato válido",
          this.$i18n.locale === "en" ? "Invalid format" : "Formato invalido",
          {
            timeout: 5000,
            showProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            titleMaxLength: 40,
            bodyMaxLength: 100,
            position: "rightBottom"
          }
        )
      }
    },
    triggerClick() {
      this.$refs.fil, e.click()
    },
    updateAspectRatio() {
      const videoElement = document.getElementById("file-upload-video")
      window.vueComponent.$emit(
        "update:aspectRatio",
        videoElement.videoWidth / videoElement.videoHeight
      )
    }
  },
  created() {
    window.vueComponent = this
  },
  unmounted() {
    const videoElement = document.getElementById("file-upload-video")
    if (videoElement) {
      videoElement.removeEventListener("loadedmetadata", this.updateAspectRatio)
    }
  },
  watch: {
    readFile() {
      if (this.type === "video") {
        this.$nextTick(() => {
          const videoElement = document.getElementById("file-upload-video")
          videoElement.addEventListener(
            "loadedmetadata",
            this.updateAspectRatio
          )
        })
      }
    }
  }
}
</script>
