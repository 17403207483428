import state from "./modulePostState"
import actions from "./modulePostActions"
import getters from "./modulePostGetters"
import mutations from "./modulePostMutations"

export default {
  namespaced: true,
  state: state,
  mutations: mutations,
  actions: actions,
  getters: getters
}
