export default [
  {
    path: "/messages",
    name: "Mensajes",
    component: () =>
      import(
        /* webpackPrefetch: true */ /* webpackChunkName: "chat" */ "@/views/chat/Chats.vue"
      ),
    meta: {
      rule: "isGlobal",
      authRequired: true,
      pageTitle: "messages",
      icon: "/static/icons/messages.svg"
    }
  },
  {
    path: "/chat_rooms",
    name: "Salas de chat",
    component: () =>
      import(
        /* webpackPrefetch: true */ /* webpackChunkName: "chat" */ "@/views/chat/PublicChats.vue"
      ),
    meta: {
      rule: "isGlobal",
      authRequired: true,
      pageTitle: "chat_rooms",
      icon: "/static/icons/messages.svg"
    }
  }
]
