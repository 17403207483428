import state from "./moduleStationRoomState"
import actions from "./moduleStationRoomActions"
import getters from "./moduleStationRoomGetters"
import mutations from "./moduleStationRoomMutations"

export default {
  namespaced: true,
  state: state,
  mutations: mutations,
  actions: actions,
  getters: getters
}
