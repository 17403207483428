export default {
  setItem({ commit }, data) {
    return new Promise(async (resolve, reject) => {
      try {
        commit("SET_ITEM", data)
        resolve(data)
      } catch (error) {
        reject(error)
      }
    })
  },
  setJeviCuts({ commit }, data) {
    commit("SET_JEVI_CUTS", data)
  },
  setItems({ commit }, data) {
    return new Promise(async (resolve, reject) => {
      try {
        commit("SET_ITEMS_NO_PAGINATE", data)
        resolve(data)
      } catch (error) {
        reject(error)
      }
    })
  },
  addUserCut({ commit }, data) {
    return new Promise(async (resolve, reject) => {
      try {
        commit("ADD_USER_CUTS", data)
        resolve(data)
      } catch (error) {
        reject(error)
      }
    })
  },
  updateElement({ commit }, data) {
    return new Promise(async (resolve, reject) => {
      try {
        commit("UPDATE_ITEM", data)
        resolve(data)
      } catch (error) {
        reject(error)
      }
    })
  },
  markAsViewed({ commit }, data) {
    return new Promise(async (resolve, reject) => {
      try {
        commit("MARK_AS_VIEWED", data)
        resolve(data)
      } catch (error) {
        reject(error)
      }
    })
  },
  setLoadNew({ commit }, status) {
    return new Promise(async (resolve, reject) => {
      try {
        commit("SET_LOAD_NEW", status)
        resolve(status)
      } catch (error) {
        reject(error)
      }
    })
  },
  deleteElement({ commit }, data) {
    return new Promise(async (resolve, reject) => {
      try {
        commit("DELETE_ELEMENT", data)
        commit("SET_LOAD_NEW", true)
        resolve(data)
      } catch (error) {
        reject(error)
      }
    })
  },
  setResumableVideo({ commit }, data) {
    return new Promise(async (resolve, reject) => {
      try {
        commit("SET_RESUMABLE_VIDEO", data)
        resolve(data)
      } catch (error) {
        reject(error)
      }
    })
  },
  changeProgressResumableVideo({ commit }, progress) {
    return new Promise(async (resolve, reject) => {
      try {
        commit("CHANGE_PROGRESS_RESUMABLE_VIDEO", progress)
        resolve(progress)
      } catch (error) {
        reject(error)
      }
    })
  }
}
