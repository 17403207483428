import router from "@/router"
import getFormData from "@/utils/convertToFormData"
import axios from "@/utils/axios.js"
import axiosV2 from "@/utils/axiosV2.js"

export default {
  login({ commit, state }, { user, aclChangeRole, i18n }) {
    return new Promise(async (resolve, reject) => {
      await axios
        .post("/auth/login", getFormData(user))
        .then(({ data: response }) => {
          if (response.code === 200) {
            let { data: user } = response
            aclChangeRole(state.roleEnum[user.id_role])
            commit("LOGIN", user.access_token.token)
            commit("SET_USER", user)
            commit("SET_USER_LOGGED_ROLE", user.id_role)
            i18n.locale = user.lang
            router.push("/home")
          }
          resolve(response)
        })
        .catch((error) => {
          console.log(error)
          reject(error)
        })
    })
  },
  googleLogin({ commit, state }, { payload, aclChangeRole, i18n }) {
    return new Promise(async (resolve, reject) => {
      await axios
        .post("/auth/google/login", getFormData({ email: payload.email }))
        .then(({ data: response }) => {
          if (response.code === 200) {
            let user = response.data
            commit("SET_USER", user)

            if (user.active == 1) {
              aclChangeRole(state.roleEnum[user.id_role])
              commit("LOGIN", user.access_token.token)
              commit("SET_USER_LOGGED_ROLE", user.id_role)
              router.push("/home")
            }
            else {
              commit("SET_GOOGLE_USER", payload)
              router.push("/signup")
            }
          }
          resolve(response)
        })
        .catch((error) => {
          console.log(error)
          reject(error)
        })
    })
  },
  googleRegister({ commit, state }, { payload, aclChangeRole, i18n }) {
    return new Promise(async (resolve, reject) => {
      await axios
        .post("/auth/google/register", getFormData(payload))
        .then(({ data: response }) => {
          if (response.code === 200) {
            let user = response.data
            commit("SET_USER", user)
            aclChangeRole(state.roleEnum[user.id_role])
            commit("LOGIN", user.access_token.token)
            commit("SET_USER_LOGGED_ROLE", user.id_role)
            router.push("/home")
          }
          resolve(response)
        })
        .catch((error) => {
          console.log(error)
          reject(error)
        })
    })
  },
  getUserLogged({ state, commit }) {
    return new Promise(async (resolve, reject) => {
      await axios
        .get(`/user/${state.user.id}`, {
          headers: {
            Authorization:
              "Bearer " + window.localStorage.getItem("access_token")
          }
        })
        .then(({ data: response }) => {
          if ([200, 201].includes(response.code)) {
            let { data: user } = response
            commit("SET_USER", user)
          }
          resolve(response)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },

  setOAuthPreferences({ commit }, data) {
    commit("SET_OAUTH_PREFERENCES", data)
  },

  updateNotifications({ commit, state }, data) {
    return new Promise(async (resolve, reject) => {
      try {
        await axios
          .get(`/notification/grouped_by_date`, {
            params: {
              id_user: state.user.id,
              order_key: "updated_at",
              order_value: "desc"
            },
            headers: {
              Authorization:
                "Bearer " + window.localStorage.getItem("access_token")
            }
          })
          .then(({ data: response }) => {
            if ([200, 201].includes(response.code)) {
              commit("SET_NOTIFICATIONS", response.data)
            }
            resolve(response)
          })
          .catch((error) => {
            reject(error)
          })
        resolve(data)
      } catch (error) {
        reject(error)
      }
    })
  },
  updateNotificationsV2({ commit, state }, data) {
    return new Promise(async (resolve, reject) => {
      try {
        await axiosV2
          .get(`/notification/grouped_by_date`, {
            params: {
              id_user: state.user.id,
              order_key: "updated_at",
              order_value: "desc"
            },
            headers: {
              Authorization:
                "Bearer " + window.localStorage.getItem("access_token")
            }
          })
          .then(({ data: response }) => {
            if ([200, 201].includes(response.code)) {
              commit("SET_NOTIFICATIONS", response.data)
            }
            resolve(response)
          })
          .catch((error) => {
            reject(error)
          })
        resolve(data)
      } catch (error) {
        reject(error)
      }
    })
  },
  pushNewNotification({ commit }, data) {
    return new Promise(async (resolve, reject) => {
      try {
        commit("PUSH_NOTIFICATION", data)
        if (data) commit("SHOW_NOTIFICATION", 1)
        resolve(data)
      } catch (error) {
        reject(error)
      }
    })
  },
  showNewNotification({ commit }, status) {
    return new Promise(async (resolve, reject) => {
      try {
        commit("SHOW_NOTIFICATION", status)
        resolve(status)
      } catch (error) {
        reject(error)
      }
    })
  },
  setNotifications({ commit }, data) {
    return new Promise(async (resolve, reject) => {
      try {
        commit("SET_NOTIFICATIONS", data)
        resolve(data)
      } catch (error) {
        reject(error)
      }
    })
  },

  setVerified({ commit }, verified) {
    return new Promise(async (resolve, reject) => {
      try {
        commit("SET_VERIFIED", verified)
        resolve(verified)
      } catch (error) {
        reject(error)
      }
    })
  },
  setUser({ commit }, user) {
    return new Promise(async (resolve, reject) => {
      try {
        commit("SET_USER", user)
        resolve(user)
      } catch (error) {
        reject(error)
      }
    })
  },

  viewNotification({ commit }, { id }) {
    return new Promise(async (resolve, reject) => {
      await axios
        .put(
          `/notification/change_viewed/${id}/1`,
          {},
          {
            headers: {
              Authorization:
                "Bearer " + window.localStorage.getItem("access_token")
            }
          }
        )
        .then(({ data: response }) => {
          if ([200, 201].includes(response.code)) {
            commit("VIEW_NOTIFICATION", id)
          }
          resolve(response)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  viewAllNotification({ state, commit }) {
    return new Promise(async (resolve, reject) => {
      await axios
        .put(
          `/notification/change_viewed/all/${state.user.id}/1`,
          {},
          {
            headers: {
              Authorization:
                "Bearer " + window.localStorage.getItem("access_token")
            }
          }
        )
        .then(({ data: response }) => {
          if ([200, 201].includes(response.code)) {
            commit("VIEW_ALL_NOTIFICATIONS")
          }
          resolve(response)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  register(context, payload) {
    return new Promise(async (resolve, reject) => {
      await axios
        .post("/user/save", getFormData(payload))
        .then(({ data: response }) => {
          if ([200, 201].includes(response.code)) {
            router.push("/login")
          }
          resolve(response)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  recovery(context, payload) {
    return new Promise(async (resolve, reject) => {
      await axios
        .post("/security/recover_password", getFormData(payload))
        .then(({ data: response }) => {
          resolve(response)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  setPassword(context, { payload, token }) {
    return new Promise(async (resolve, reject) => {
      await axios
        .put(`/security/set_new_password/${token}`, getFormData(payload))
        .then(({ data: response }) => {
          if ([200, 201].includes(response.code)) {
            router.push("/login")
          }
          resolve(response)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  logout({ commit }, aclChangeRole) {
    return new Promise(async (resolve, reject) => {
      await axios
        .post(
          "/auth/logout",
          {},
          {
            headers: {
              Authorization:
                "Bearer " + window.localStorage.getItem("access_token")
            }
          }
        )
        .then(({ data: response }) => {
          aclChangeRole("public")
          commit("LOGOUT")
          window.localStorage.removeItem("vuex")
          router.push("/").catch(() => {})
          resolve(response)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  disabledUser({ commit }) {
    return new Promise(async (resolve) => {
      commit("LOGOUT")
      window.localStorage.removeItem("vuex")
      router.push("/disabled").catch(() => {})
      resolve({})
    })
  },
  sendConfirmation(context, email) {
    return new Promise(async (resolve, reject) => {
      await axios
        .post("/security/resend_email", getFormData(email))
        .then(({ data: response }) => {
          resolve(response)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  changeImageProfile({ commit }, { id, payload }) {
    return new Promise(async (resolve, reject) => {
      await axios
        .put(
          `/user/change_profile_picture/${id}`,
          getFormData(
            { image_profile: payload },
            {
              headers: {
                Authorization:
                  "Bearer " + window.localStorage.getItem("access_token")
              }
            }
          )
        )
        .then(({ data: response }) => {
          if ([200, 201].includes(response.code)) {
            let { data: user } = response
            commit("SET_IMAGE", user.image_profile)
          }
          resolve(response)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  update({ commit }, { id, payload }) {
    return new Promise(async (resolve, reject) => {
      await axios
        .put(`/user/update/${id}`, getFormData(payload), {
          headers: {
            Authorization:
              "Bearer " + window.localStorage.getItem("access_token")
          }
        })
        .then(({ data: response }) => {
          if ([200, 201].includes(response.code)) {
            let { data: user } = response
            commit("SET_USER", user)
          }
          resolve(response)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  storiesAvailable({ commit }, data) {
    return new Promise(async (resolve, reject) => {
      try {
        commit("STORIES_AVAILABLE", data)
        resolve(data)
      } catch (error) {
        reject(error)
      }
    })
  },
  storyViewed({ commit }, data) {
    return new Promise(async (resolve, reject) => {
      try {
        commit("STORY_VIEWED", data)
        resolve(data)
      } catch (error) {
        reject(error)
      }
    })
  }
}
