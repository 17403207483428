export default [
  {
    path: "/",
    name: "Home no auth",
    component: () =>
      import(
        /* webpackPrefetch: true */ /* webpackChunkName: "auth" */ "@/views/TempHome.vue"
      ),
    meta: {
      rule: "isPublic",
      authRequired: false
    }
  },
  {
    path: "/login",
    name: "Login",
    component: () =>
      import(
        /* webpackPrefetch: true */ /* webpackChunkName: "auth" */ "@/views/auth/Login.vue"
      ),
    meta: {
      rule: "isPublic",
      authRequired: false
    }
  },
  {
    path: "/signup",
    name: "Register",
    component: () =>
      import(
        /* webpackPrefetch: true */ /* webpackChunkName: "auth" */ "@/views/auth/Register.vue"
      ),
    meta: {
      rule: "isPublic",
      authRequired: false
    }
  },
  {
    path: "/reset_password",
    name: "Reset",
    component: () =>
      import(
        /* webpackPrefetch: true */ /* webpackChunkName: "auth" */ "@/views/auth/ResetPassword.vue"
      ),
    meta: {
      rule: "isPublic",
      authRequired: false
    }
  }
]
