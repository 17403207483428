import state from "./moduleRandomPhotoState"
import actions from "./moduleRandomPhotoActions"
import getters from "./moduleRandomPhotoGetters"
import mutations from "./moduleRandomPhotoMutations"

export default {
  namespaced: true,
  state: state,
  mutations: mutations,
  actions: actions,
  getters: getters
}
